import { IBus } from "@sg-widgets/platform-api";
import { WidgetConfiguration } from "@sg-widgets/shared-core";
import { getSgConnectUrl } from "../../common/configuration/services-url";
import { useWidgetBusData } from "@sg-widgets/react-core";

type UserInfo = {
  sub: string;
  zoneinfo: string;
  postal_country: string;
  mail: string;
  igg: string;
  last_name: string;
  login_ad: string;
  company_bdr_name: string;
  given_name: string;
  locale: string;
  contact_id: string;
  sgconnect_id: string;
  rc_local_sigle: string;
  sesame_id: string;
  user_bdr_id: string;
  company_bdr_level: string;
  name: string;
  is_sg_group_user: string;
  family_name: string;
  first_name: string;
  company_bdr_id: string;
  preferred_language: string;
  origin_network: "LAN" | "WAN" | "Internet";
  auth_level: "L1" | "L2" | "L3" | "L4";
};

export const getToken = (bus: IBus): string | undefined => {
  const authorization = bus.dangerouslyGetCurrentValue<string>(
    "sg-connect.access-token"
  );

  return authorization;
};

export const useUserInfo = (): UserInfo | undefined => {
  const user = useWidgetBusData<UserInfo>("sg-connect.user-info");
  return user;
};

export const initUserInfo = (config: WidgetConfiguration): void => {
  const user = config.bus.dangerouslyGetCurrentValue("sg-connect.user-info");
  const token = getToken(config.bus);
  if (user || !token) {
    return;
  }
  config.bus.publish("sg-connect.user-info", {});
  const url = `${getSgConnectUrl(config.environment)}/oauth2/userinfo`;
  config.log(`Getting UserInfo from "${url}"`);
  config
    .fetch(url, {
      headers: {
        accept: "application/json",
        authorization: token,
      },
      method: "GET",
      mode: "cors",
    })
    .then(response => response.json())
    .then(data => {
      config.bus.publish("sg-connect.user-info", data);
    })
    .catch((): undefined => undefined);
};

export const useUserExistsInPops = (): boolean => {
  const user = useUserInfo();
  if (!user) {
    return false;
  }
  const result = user.igg;
  return !!result;
};

export const useUserIsInternal = (): boolean => {
  const user = useUserInfo();
  if (!user) {
    return false;
  }
  const result = user.is_sg_group_user;
  return !!result && result.toLowerCase() === "true";
};

export const useUserContactId = (): string => {
  const user = useUserInfo();
  if (!user) {
    return "";
  }
  return user.contact_id;
};
