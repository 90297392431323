import { RequestData, ResponseType } from "../models/RequestAccessModels";
import { SgConnectFetchResult } from "@sg-widgets/react-core";
import { WidgetConfiguration } from "@sg-widgets/shared-core";
import { getApiRequestAccessUrl } from "../../common/configuration/services-url";
import { HttpClient } from "../../common/api/httpClient";
import { getSgConnectFetchErrorMessageByStatus } from "../../common/message/fetch-error-message";
import { PopsError } from "../models/RequestAccessPopsModels";

export const sendGlobalRequestAccess = (
  endpoint: string,
  content: RequestData,
  sgConnectFetch: SgConnectFetchResult,
  config: WidgetConfiguration
): Promise<ResponseType | null> =>
  new Promise((resolve, reject) => {
    if (sgConnectFetch.fetch) {
      const http = new HttpClient(sgConnectFetch.fetch, config.error, undefined, false);
      const baseUrl = getApiRequestAccessUrl(config.environment);
      const response = http.postReponse<RequestData | PopsError>(
        `${baseUrl}/v1/requests${endpoint}`,
        content
      );
      resolve(response);
    } else {
      reject(getSgConnectFetchErrorMessageByStatus(sgConnectFetch?.status));
    }
  });
