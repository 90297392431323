import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

export const ReactPortal: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const container = useState(document.createElement('div'))[0];

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container)
    }
  }, []);

  return createPortal(children, container);
}