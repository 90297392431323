import React, { FC, PropsWithChildren, useEffect, useState, createContext, useContext } from "react";
import { useWidgetConfiguration } from "@sg-widgets/react-core";

type SgBootstrapConfig = {
  version: number,
  className: string,
  urls: string[],
  inlineStyle: string,
}

const baseInlineStyle = `
.sgbs-4 .hide-on-sgbs-4 {
  display: none!important;
}
.sgbs-5 .hide-on-sgbs-5 {
  display: none!important;
}
`

const defaultBootstrapVersion = 4;
const sgBootstrapConfigs: { [name: string]: SgBootstrapConfig } = {
  4: {
    version: 4,
    className: 'sgbs-4',
    inlineStyle: baseInlineStyle,
    urls: [
      "https://shared.sgmarkets.com/sg-bootstrap/v4/latest/sg-bootstrap-standard.min.css",
      "https://shared.sgmarkets.com/sg-bootstrap/v4/icons/index.min.css",
    ]
  }
  ,
  5: {
    version: 5,
    className: 'sgbs-5',
    inlineStyle: baseInlineStyle,
    urls: [
      "https://shared.sgmarkets.com/sg-bootstrap/v5/5.5.1/core/css/sg-bootstrap-standard.css",
      "https://shared.sgmarkets.com/sg-bootstrap/v5/5.5.1/icons/index.min.css",
    ],
  }
}

const SgBootstrapContext = createContext<SgBootstrapConfig>(sgBootstrapConfigs[defaultBootstrapVersion]);
export const useSgBootstrapContext = (): SgBootstrapConfig => useContext(SgBootstrapContext);

export interface Props {
  extraUrls?: string[];
  version?: string
}

const sgBootstrapKey = '__sg-widgets__sg-bootstrap-version';

const computeBusVersion = (version?: string): string => {
  if (!version) {
    return '';
  }

  // Transform vX@yyy to X
  version = version.split('@')[0];
  version = version.startsWith('v') ? version.substring(1, version.length) : version;

  return version;
};

export const WithSGBootstrap: FC<PropsWithChildren<Props>> = ({
  children,
  extraUrls = [],
  version = ''
}) => {
  const config = useWidgetConfiguration();
  const [busSgBsVersion, setBusSgBsVersion] = useState(config.bus.dangerouslyGetCurrentValue<string>(sgBootstrapKey))

  useEffect(() => {
    const handle = config.bus.subscribe<string>(sgBootstrapKey, (val) => setBusSgBsVersion(val));
    return () => {
      config.bus.unsubscribe(handle);
    };
  }, []);

  const sgBootstrapConfig = sgBootstrapConfigs[version] ?? sgBootstrapConfigs[computeBusVersion(busSgBsVersion)] ?? sgBootstrapConfigs[defaultBootstrapVersion];

  useEffect(() => {
    if (version && !sgBootstrapConfigs[version]) {
      config.error(`Unknown SG Bootsrap version ${version}. Availables versions : ${Object.keys(sgBootstrapConfigs).join(', ')}`);
    }
  }, [version])

  return <span>
    <style type="text/css">
      {sgBootstrapConfig.urls.map(url => `@import url(${url});\n`)}
      {extraUrls.map(url => `@import url(${url});\n`)}
      {sgBootstrapConfig.inlineStyle}
    </style>
    <SgBootstrapContext.Provider value={sgBootstrapConfig}>
      <div className={sgBootstrapConfig.className}>
        {children}
      </div>
    </SgBootstrapContext.Provider>
  </span>
};
