import React, { useContext, useState, useCallback } from "react";
import { HeaderModal } from "../modal-common/HeaderModal";
import { PopsStepper } from "../step/PopsStepper";
import { RequestAccessPopsWorkflowContext } from "../../contexts/RequestAccessPopsWorkflowContext";
import { Loading } from "../../../common/components/Loading";

export const PopsProfileRequestContent: React.FC = () => {
  const popsContext = useContext(RequestAccessPopsWorkflowContext);

  const [fullDescription, setFullDescription] = useState([] as string[]);

  const toggleProfile = useCallback(
    (name: string) => {
      const profile = [...popsContext.selectedProfile];
      const index = profile.indexOf(name);
      if (index >= 0) {
        profile.splice(index, 1);
      } else {
        profile.push(name);
      }
      popsContext.updateSelectedProfiles(profile);
    },
    [popsContext.selectedProfile]
  );

  return (
    <>
      <HeaderModal>
        <PopsStepper current={1} />
      </HeaderModal>
      <div className="modal-body" style={{ maxHeight: "50vh" }}>
        <p className="mb-3">1. Select your profile(s).</p>
        {popsContext.loadingProfile && <Loading />}
        {popsContext.popsProfile?.map((p) => {
          const truncate = 39;
          const maxchar = 55;
          const selected = popsContext.selectedProfile.indexOf(p.name) >= 0;
          const hasFullDescriptionVisible =
            fullDescription.indexOf(p.name) >= 0 ||
            p.description.length < maxchar;
          return (
            <div
              key={p.name}
              className={`card card-bordered card-bordering mb-2 ${
                (selected && "active") || ""
              }`}
            >
              <div
                className="row align-items-center border p-3 mx-0"
                onClick={() => toggleProfile(p.name)}
              >
                <div className="col-10 px-0 mx-0">
                  <p className="card-title mb-1 text-large fs-4 font-weight-medium fw-medium mb-3">
                    {p.name}
                  </p>
                  <p className="card-text mb-3">
                    <span className="font-italic fst-italic font-weight-normal fw-normal">
                      {(hasFullDescriptionVisible && p.description) ||
                        `${p.description.substring(0, truncate)}… `}
                    </span>
                    {hasFullDescriptionVisible || (
                      <a
                        href={"#"}
                        className="text-small fs-6"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setFullDescription([...fullDescription, p.name]);
                        }}
                      >
                        Show more
                      </a>
                    )}
                  </p>
                </div>
                <div className="col-2">
                  <span className="float-right">
                    <i className={`icon icon-md`}>
                      {(selected && "check_box") || "check_box_outline_blank"}
                    </i>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-text-icon btn-lg btn-outline-primary"
          onClick={() => popsContext.changeWorkflowState("INFO")}
          disabled={
            popsContext.needsProfile &&
            popsContext.popsProfile &&
            popsContext.selectedProfile.length === 0
          }
        >
          Next <i className={`icon icon-sm`}>keyboard_arrow_right</i>
        </button>
      </div>
    </>
  );
};
