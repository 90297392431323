import React, { useContext, useEffect, useState, useCallback } from "react";
import { HeaderModal } from "../modal-common/HeaderModal";
import { PopsStepper } from "../step/PopsStepper";
import { RequestAccessPopsWorkflowContext } from "../../contexts/RequestAccessPopsWorkflowContext";
import { PopsAdditionalInfo, defaultPopsCommentName } from "../../models/RequestAccessPopsModels";
import { TextAreaSelection } from "./PopsAdditionalInfoContentSelector";
import { RequestAccessCheckboxes } from "../content-request-sgmarkets/RequestAccessCheckboxes";

type AdditionalInfo = {
  requiredInfo: string[];
  listInfo: string[];
  selectedInfo: { [key: string]: string[] };
};

const additionalInfoIsNeededByProfiles = (
  addinfo: PopsAdditionalInfo,
  profile: string[],
  additionalInfoResult: AdditionalInfo
): boolean => {
  return (
    addinfo.profiles &&
    (addinfo.profiles.length === 0 ||
      (profile.some(p => addinfo.profiles.indexOf(p) >= 0) &&
        additionalInfoResult.listInfo.indexOf(addinfo.name) < 0))
  );
};

const isCommentNotVisible = (
  addinfo: PopsAdditionalInfo,
  needsAdditionalInfo: boolean,
  additionalInfoResult: AdditionalInfo
): boolean =>
  additionalInfoResult.listInfo.length > 0 &&
  addinfo.required === false &&
  needsAdditionalInfo === true;

const getAdditionalInfo = (
  profile: string[],
  addinfos: PopsAdditionalInfo[],
  selectedInfo: { [key: string]: string[] },
  needsAdditionalInfo: boolean
): AdditionalInfo => {
  const additionalInfoResult: AdditionalInfo = {
    requiredInfo: [],
    listInfo: [],
    selectedInfo: {},
  };
  for (const i in addinfos) {
    if (!addinfos[i]) {
      continue;
    }
    const addinfo = addinfos[i];
    if (
      addinfo.name === defaultPopsCommentName &&
      isCommentNotVisible(addinfo, needsAdditionalInfo, additionalInfoResult)
    ) {
      continue;
    }
    if (
      additionalInfoIsNeededByProfiles(addinfo, profile, additionalInfoResult)
    ) {
      additionalInfoResult.listInfo.push(addinfo.name);
      if (addinfo.required) {
        additionalInfoResult.requiredInfo.push(addinfo.name);
      }
      additionalInfoResult.selectedInfo[addinfo.name] =
        selectedInfo[addinfo.name] || [];
      if (additionalInfoResult.selectedInfo[addinfo.name].length === 0) {
        additionalInfoResult.selectedInfo[addinfo.name] = addinfo.values
          .filter(
            ({ description }) =>
              description && description.toLocaleLowerCase() === "default"
          )
          .map(({ name }) => name);
      }
    }
  }
  return additionalInfoResult;
};

const hasAllInfoRequiredComplete = (
  additionalInformation: AdditionalInfo
): boolean => {
  let result;

  result = true;
  additionalInformation.requiredInfo.forEach(info => {
    const content = additionalInformation.selectedInfo[info] || [];
    if (!(content.length > 0 && content[0].length > 0)) {
      result = false;
    }
  });
  return result;
};

const findByName = (
  array: PopsAdditionalInfo[],
  value: string,
  comment: { description: string; required: boolean }
): PopsAdditionalInfo => {
  for (const i in array) {
    if (array[i].name === value) {
      return array[i];
    }
  }
  if (value === defaultPopsCommentName) {
    return {
      name: defaultPopsCommentName,
      type: "Text Area",
      display: comment.description || "Comment",
      required: comment.required,
      profiles: [],
      values: [],
    };
  }

  // should not occurs
  return {} as PopsAdditionalInfo;
};

const singleSelection = (
  name: string,
  value: string,
  updateSelectedInfo: (name: string, value: string[]) => void
): void => {
  updateSelectedInfo(name, [value]);
};

const toggleSelection = (
  name: string,
  value: string,
  selectedInfo: { [key: string]: string[] },
  updateSelectedInfo: (name: string, value: string[]) => void
): void => {
  const selection = [...(selectedInfo[name] || [])];
  const index = selection.indexOf(value);
  if (index >= 0) {
    selection.splice(index, 1);
  } else {
    selection.push(value);
  }
  updateSelectedInfo(name, selection);
};

export const PopsAdditionalInfoContent: React.FC = () => {
  const popsContext = useContext(RequestAccessPopsWorkflowContext);

  const [additionalInformation, setAdditionalInformation] = useState({
    listInfo: [],
    requiredInfo: [],
    selectedInfo: {},
  } as AdditionalInfo);

  useEffect(() => {
    const data = getAdditionalInfo(
      popsContext.selectedProfile,
      [
        ...popsContext.popsAdditionalInfo,
        {
          name: defaultPopsCommentName,
          type: "Text Area",
          display: popsContext.comment.description || "Comment",
          required: popsContext.comment.required,
          profiles: [],
          values: [],
        },
      ],
      popsContext.selectedInfo,
      popsContext.needsAnalyticalParam
    );
    setAdditionalInformation(data);
  }, []);

  const updateSelectedInfo = useCallback(
    (name: string, value: string[]) => {
      setAdditionalInformation({
        ...additionalInformation,
        selectedInfo: {
          ...additionalInformation.selectedInfo,
          [name]: value,
        },
      });
    },
    [setAdditionalInformation, additionalInformation]
  );

  return (
    <div>
      <HeaderModal>
        <PopsStepper current={2} />
      </HeaderModal>
      <div className="modal-body" style={{ maxHeight: "50vh" }}>
        {additionalInformation.listInfo.map((name, i) => {
          const adinfo = findByName(
            popsContext.popsAdditionalInfo,
            name,
            popsContext.comment
          );
          return (
            <div className="mb-3 pb-3" key={`${i}.${adinfo.name}`}>
              <p className="mb-2">
                {i + 1}. {adinfo.display || adinfo.name}{" "}
                {(adinfo.required && (
                  <span className="text-socgen">{" *"}</span>
                )) || <span className="text-secondary">{" (Optional)"}</span>}
              </p>
              <>
                {adinfo.type.toLocaleLowerCase() ===
                  "List-Multiple Selections".toLocaleLowerCase() && (
                    <RequestAccessCheckboxes
                      options={adinfo.values.map(x => ({ key: x.name, label: x.description ? `${x.name} - ${x.description}` : x.name }))}
                      selectedKeys={additionalInformation.selectedInfo[adinfo.name] || []}
                      onChange={(value: string) => {
                        toggleSelection(
                          adinfo.name,
                          value,
                          additionalInformation.selectedInfo,
                          updateSelectedInfo
                        );
                      }}
                    />
                  )}
                {adinfo.type.toLocaleLowerCase() ===
                  "List-Single selection".toLocaleLowerCase() && (
                    <RequestAccessCheckboxes
                      singleSelection
                      options={adinfo.values.map(x => ({ key: x.name, label: x.description ? `${x.name} - ${x.description}` : x.name }))}
                      selectedKeys={additionalInformation.selectedInfo[adinfo.name] || []}
                      onChange={(value: string) => {
                        singleSelection(adinfo.name, value, updateSelectedInfo);
                      }}
                    />
                  )}
                {adinfo.type.toLocaleLowerCase() ===
                  "Text Area".toLocaleLowerCase() && (
                    <TextAreaSelection
                      selected={
                        additionalInformation.selectedInfo[adinfo.name] || [""]
                      }
                      values={null}
                      callback={(value: string) => {
                        singleSelection(adinfo.name, value, updateSelectedInfo);
                      }}
                    />
                  )}
              </>
            </div>
          );
        })}
      </div>
      <div className="modal-footer">
        <fieldset className="d-flex w-100">
          <button
            type="button"
            className="btn btn-icon-text btn-lg btn-outline-primary"
            onClick={() => {
              popsContext.updateSelectedAdditonalInformation(
                additionalInformation.selectedInfo
              );
              popsContext.changeWorkflowState("PROFILE");
            }}
          >
            <i className={`icon icon-sm`}>keyboard_arrow_left</i> Back
          </button>
          <div className="flex-grow-1" />
          <button
            type="button"
            className="btn btn-text-icon btn-lg btn-outline-primary"
            onClick={() => {
              popsContext.updateSelectedAdditonalInformation(
                additionalInformation.selectedInfo
              );
              popsContext.changeWorkflowState("SUMMARY");
            }}
            disabled={!hasAllInfoRequiredComplete(additionalInformation)}
          >
            Next <i className={`icon icon-sm`}>keyboard_arrow_right</i>
          </button>
        </fieldset>
      </div>
    </div>
  );
};
