import React, { useContext } from "react";
import { RequestAccessPopsWorkflowContext } from "../../contexts/RequestAccessPopsWorkflowContext";
import { RequestAccessGlobalWorkflowContext } from "../../contexts/RequestAccessGlobalWorkflowContext";
import { BusinessJustificationContent } from "../content-request-sgmarkets/BusinessJustificationContent";
import { PopsDefaultContent } from "./PopsDefaultContent";
import { LoadingContent } from "../content-request-sgmarkets/LoadingContent";
import { PopsStartRequestContent } from "./PopsStartRequestContent";
import { PopsProfileRequestContent } from "./PopsProfileRequestContent";
import { PopsAdditionalInfoContent } from "./PopsAdditionalInfoContent";
import { PopsSummaryContent } from "./PopsSummaryContent";
import { PopsSubmitContent } from "./PopsSubmitContent";

export const ModalRequestPopsFactory: React.FC = () => {
  const globalContext = useContext(RequestAccessGlobalWorkflowContext);
  const popsContext = useContext(RequestAccessPopsWorkflowContext);

  if (popsContext.popsError) {
    if (globalContext.site) return <BusinessJustificationContent />;
    return <PopsDefaultContent status="error" />;
  }
  if (popsContext.loadingAccess || popsContext.loadingProfile)
    return <LoadingContent />;
  if (popsContext.status.toLowerCase() !== "not granted")
    return <PopsDefaultContent status={popsContext.status} />;
  if (popsContext.workflow === "PROFILE") return <PopsProfileRequestContent />;
  if (popsContext.workflow === "INFO") return <PopsAdditionalInfoContent />;
  if (popsContext.workflow === "SUMMARY") return <PopsSummaryContent />;
  if (popsContext.workflow === "SUBMIT") return <PopsSubmitContent />;
  return <PopsStartRequestContent />;
};
