import { getComponentUrl } from "../../configuration/components-url";

if (!window.SGWTWidgetDependencies) {
    window.SGWTWidgetDependencies = { dependencies: [] }
}

function addScript(srcFile: string): Promise<void> {
    return new Promise((resolve, reject) => {
        const currentScript: HTMLScriptElement | SVGScriptElement | null = document.currentScript;

        const script: HTMLScriptElement = document.createElement('script');
        script.onerror = () => {
            reject(`The script ${srcFile}.js is not accessible.`)
        };

        if (currentScript && currentScript.parentNode) {
            // insert the script tag into the DOM before the current script
            currentScript.parentNode.insertBefore(script, currentScript);
        } else {
            // else at the end of the body tag
            document.body.appendChild(script);
        }
        script.src = srcFile
        script.onload = () => { resolve() }
    })
}

export enum WidgetName {
    arclabPicker = 'arclab-picker',
    arclabSelect = 'arclab-select',
    sgwtHighcharts = 'sgwt-highcharts',
    idyRequestAccess = 'idy-request-access',
}

export async function injectWidget(widgetName: WidgetName): Promise<void> {
    const sgwtWidgetConfiguration = window.SGWTWidgetConfiguration;
    const sgwtWidgetDependencies = window.SGWTWidgetDependencies;
    const widgetUrl = getComponentUrl(widgetName, sgwtWidgetConfiguration.environment);

    if (widgetName && widgetUrl) {
        // check if widget have already been loaded
        // or if widget tag is already know as a webcomponent (through customElements)
        // for more info on customElements see https://developer.mozilla.org/en-US/docs/Web/API/CustomElementRegistry
        if (sgwtWidgetDependencies.dependencies.indexOf(widgetName) < 0
            && customElements.get(widgetName) === undefined
        ) {
            sgwtWidgetDependencies.dependencies.push(widgetName); // Add the current widget name
            await addScript(widgetUrl)
        }
    }
}
