import { Dispatch, ReducerAction, ReducerState, useReducer } from "react";
import { RequestData } from "../models/RequestAccessModels";
import { RequestAccessGlobalWorkflowReducerStore } from "../models/RequestAccessStore";
import { Action } from './RequestAccessGlobalWorkflowAction';

export type RequestAccessGlobalWorkflowType = {
  sendRequestAccess: (endpoint: string, data: RequestData) => void;
  resetRequestAccess: () => void;
};

export const providerInitialState = {
  lang: "en",
  loading: false,
  isFinished: false,
  isSuccess: false,
  message: "",
  isUserOffline: false,
  onBehalfOf: { isFetching: true },
} as RequestAccessGlobalWorkflowReducerStore;

const requestAccessGlobalWorkflowReducer = (
  state: RequestAccessGlobalWorkflowReducerStore,
  action: Action
): RequestAccessGlobalWorkflowReducerStore => {
  switch (action.type) {
    case "error":
      return {
        ...state,
        isFinished: true,
        isSuccess: false,
        loading: false,
        message: action.payload,
      };
    case "updateSite":
      return { ...state, site: action.payload };
    case "updateLang":
      return { ...state, lang: action.payload };
    case "loading":
      return { ...state, loading: true };
    case "success":
      return {
        ...state,
        isFinished: true,
        isSuccess: true,
        loading: false,
        message: action.payload,
      };
    case "reset":
      return {
        ...state,
        isFinished: providerInitialState.isFinished,
        isSuccess: providerInitialState.isSuccess,
        loading: providerInitialState.loading,
        message: providerInitialState.message,
      };
    case "onBehalfOf":
      return {
        ...state,
        onBehalfOf: {
          isFetching: false,
          isInternal: action.payload.isInternal,
        }
      }
    default:
      return state;
  }
};

export const useGlobalWorkflowReducer = (): [ReducerState<typeof requestAccessGlobalWorkflowReducer>, Dispatch<ReducerAction<typeof requestAccessGlobalWorkflowReducer>>] =>
  useReducer(requestAccessGlobalWorkflowReducer, providerInitialState);
