import React, { useEffect } from "react";
import {
  widgetize,
  WidgetPropsMapping,
  useWidgetConfiguration,
} from "@sg-widgets/react-core";
import { WidgetProps } from "./models/RequestAccessWidgetProps";
import { RequestAccessWidget } from "./components/RequestAccessWidget";
import { RequestAccessGlobalWorkflowProvider } from "./contexts/RequestAccessGlobalWorkflowContext";
import { initUserInfo } from "./services/SgConnectService";
import { WithSGBootstrap } from "../common/components/WithSGBootstrap";

const SgMarketsContextualLinks: React.FC<WidgetProps> = (
  props: WidgetProps
) => {
  const config = useWidgetConfiguration();

  useEffect(() => {
    initUserInfo(config);
  }, [config]);

  const sanitizeProps: WidgetProps = {
    ...props,
    classbtn: props.classbtn || "btn btn-lg btn-discreet-socgen",
    colorSubmitBtn: `btn-${props.colorSubmitBtn || "socgen"}`,
  };

  return (
    <WithSGBootstrap version={sanitizeProps.sgBootstrap}>
      <RequestAccessGlobalWorkflowProvider widgetProps={sanitizeProps}>
        <RequestAccessWidget />
      </RequestAccessGlobalWorkflowProvider>
    </WithSGBootstrap>
  );
};

widgetize(
  "sgmarkets-request-access",
  SgMarketsContextualLinks,
  {
    mode: WidgetPropsMapping.asString({
      description: "render mode (default: modal)",
    }),
    ressourceId: WidgetPropsMapping.asString({
      description: "RessourceId of the service",
    }),
    classbtn: WidgetPropsMapping.asString({
      description: "Custom Request Access Css href",
    }),
    icid: WidgetPropsMapping.asString({
      description: "Icid of the user for `on behalf of` (only for SGM request)",
    }),
    colorSubmitBtn: WidgetPropsMapping.asString({
      description:
        "Custom Request Access Sumbit Button (default: `socgen` | example: `primary`, `secondary`, `info`..)",
    }),
    url: WidgetPropsMapping.asString({
      description: "url of the service",
    }),
    useIcon: WidgetPropsMapping.asObject({
      description:
        "use icon instead of 'Request Access' text in main button (value: `true` or `false`)",
    }),
    popsIdentifier: WidgetPropsMapping.asString({
      description: "Pops identifier of the application",
    }),
    sourceUrl: WidgetPropsMapping.asString({
      description: "Source website url",
    }),
    sourceContext: WidgetPropsMapping.asString({
      description: "Context (buttom top, header, footer)",
    }),
    site: WidgetPropsMapping.asObject({
      description:
        'Site information to avoid http request, example:<code>{"url":"https://sgmarkets.com","serviceKey":"DEMO_SGM","popsIdentifier":"","name":"SGM Demo"}</code>',
    }),
    sgBootstrap: WidgetPropsMapping.asString({
      description: "SG Bootstrap version. (value: `4` or `5`)",
    }),
    emitRequestSent: WidgetPropsMapping.asEventEmitter("request-sent", {
      description:
        "`requestSent` request task object | The request task object that was sent",
    }),
    emitGetService: WidgetPropsMapping.asEventEmitter("get-service", {
      description: "`service` object | The service object retrieved by api ",
    }),
  },
  { neverUseShadowDOM: false, neverInjectGlobalCss: true }
);
