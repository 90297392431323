import en from "../../locales/requestAccess.en.json";
import es from "../../locales/requestAccess.es.json";
import de from "../../locales/requestAccess.de.json";
import fr from "../../locales/requestAccess.fr.json";
import ja from "../../locales/requestAccess.ja.json";
import zh from "../../locales/requestAccess.zh.json";
import ru from "../../locales/requestAccess.ru.json";

type Translation = {
  requestAccess: string;
  businessJustificationDetails: string;
  iamDetails: string;
  businessJustificationPlaceholder: string;
  cancel: string;
  sendRequest: string;
};

type Lang = "en" | "es" | "de" | "fr" | "ja" | "zh" | "ru";

const translation: { [Key in Lang]: Translation } = {
  en,
  es,
  de,
  fr,
  ja,
  zh,
  ru,
};

export const requestAccessTranslate = (
  language: string,
  text: keyof Translation
): string => {
  const lang: Lang = language as Lang;
  if (translation[lang] && translation[lang][text] !== null)
    return translation[lang][text] as string;
  return translation["en"][text];
};
