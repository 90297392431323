export type PopsContentInfo = { [key: string]: string[] };

export const defaultPopsCommentName = "__comment__";

export type PopsError = { message: string };

export type PopsProfile = {
  name: string;
  description: string;
};

export type PopsAdditionalInfo = {
  name: string;
  type: string;
  display: string;
  required: boolean;
  profiles: string[];
  values: Array<{
    name: string;
    description: string;
  }>;
};

export type PopsApplicationProfileResult = {
  resultStatus: string;
  errorCode: string;
  errorMessage: string;
  profile: PopsProfile[];
  additionalInfo: PopsAdditionalInfo[];
};

export type PopsAccessResult = {
  status: string;
  needsProfile: boolean;
  needsAdditionalInfo: boolean;
  deployedInPops: boolean;
  commentDescription: string;
  commentRequired: boolean;
  resultStatus: string;
  errorCode: string;
  errorMessage: string;
};
