import React, { useContext } from "react";
import { HeaderModal } from "../modal-common/HeaderModal";
import { RequestAccessPopsWorkflowContext } from "../../contexts/RequestAccessPopsWorkflowContext";

export const PopsStartRequestContent: React.FC = () => {
  const popsContext = useContext(RequestAccessPopsWorkflowContext);
  return (
    <div>
      <HeaderModal />
      <div className="modal-body">
        <p className="text-xlarge fs-3 font-weight-medium fw-medium mb-3">
          This service requires an IAM request.
        </p>
        <p className="mb-3">
          For more information, please contact your IAM Analysts by specifying
          the application and profile needed. <br />
          Your IAM Analysts are displayed My Data tab in{" "}
          <a href="https://iam.sgmarkets.com/data">IAM</a>
        </p>
      </div>
      <div className="modal-footer pt-0">
        <button
          type="button"
          className="btn btn-lg btn-primary mr-3"
          onClick={() => popsContext.changeWorkflowState("PROFILE")}
        >
          Begin request
        </button>
      </div>
    </div>
  );
};
