import { PopsError } from "./RequestAccessPopsModels";

export type Site = {
  name: string;
  ressourceId: string;
  serviceKey: string;
  url?: string;
  popsIdentifier?: string;
  resourceProfiles?: ResourceProfile[];
  activateIAMAccessWidget?: boolean;
};

export type ResourceProfile = {
  name: string;
  id: string;
  exposure: 'EXTERNAL' | 'INTERNAL' | 'ALL';
};

export const defaultSite: Site = {
  serviceKey: "__EMPTY_SGMARKETS__",
  ressourceId: "00000000-0000-0000-0000-000000000000",
  name: "SG Markets",
  url: "https://sgmarkets.com/",
  popsIdentifier: "",
};

export type PopsRequestData = {
  serviceName: string;
  profiles: string[];
  params: Array<{ name: string; values: string[] }>;
  comments?: string;
};

export type RequestData = DefaultRequestData | PopsRequestData;

export type DefaultRequestData = {
  serviceKey: string;
  icId?: string;
  comment?: string;
  email?: string;
  url?: string;
  sourceUrl?: string;
  sourceContext?: string;
  resourceProfiles?: ResourceProfile[];
};

export type ResponseType = {
  success: boolean;
  result?: RequestData | PopsError;
  status: string;
  statusCode: number;
};

export type OnBehalfOf = {
  isFetching: boolean;
  isInternal?: boolean;
};
