import React, { MouseEvent } from "react";
import { RequestAccessGlobalWorkflowConsumer } from "../../contexts/RequestAccessGlobalWorkflowContext";
import { DefaultModal } from "../modal-common/DefaultModal";
import { ModalRequestSgMarketsFactory } from "../content-request-sgmarkets/ModalRequestSgMarketsFactory";
import { SuccessModal } from "../modal-common/SuccessModal";
import { ErrorModal } from "../modal-common/ErrorModal";
import { getToken } from "../../services/SgConnectService";
import { useWidgetConfiguration } from "@sg-widgets/react-core";
import { getFormRequestAccessUrl } from "../../../common/configuration/services-url";
import { ReactPortal } from "../ReactPortal";
import { requestAccessTranslate } from "../../services/requestAccessTranslate";
import { useSgBootstrapContext } from "../../../common/components/WithSGBootstrap";

const ModalContentFactory: React.FC = () => (
  <RequestAccessGlobalWorkflowConsumer>
    {({ isSuccess, isFinished, message }) => {
      if (isFinished) {
        if (isSuccess) {
          return (
            <SuccessModal
              title="Thank you for your interest."
              message={message}
            />
          );
        }
        return <ErrorModal message={message} />;
      }
      return (
        <DefaultModal>
          <ModalRequestSgMarketsFactory />
        </DefaultModal>
      );
    }}
  </RequestAccessGlobalWorkflowConsumer>
);

const ButtonModalAccessText: React.FC = () => (
  <RequestAccessGlobalWorkflowConsumer>
    {({ widgetProps, lang }) => {
      if (widgetProps.useIcon) return <i className="icon icon-md">lock</i>;
      return requestAccessTranslate(lang, "requestAccess");
    }}
  </RequestAccessGlobalWorkflowConsumer>
);

export const ButtonModalAccess: React.FC = () => {
  const [show, setShow] = React.useState(false);
  const sgBootstrap = useSgBootstrapContext();

  const handleClose = (): void => setShow(false);
  const handleShow = (): void => setShow(true);

  const { bus, environment } = useWidgetConfiguration();
  const token = getToken(bus);

  const handleModalClick = (event: MouseEvent): void => {
    event.stopPropagation();

    if (!event.target) {
      return;
    }

    if (
      (event.target as HTMLElement).getAttribute("data-dismiss") === "modal"
    ) {
      handleClose();
    }
  };

  if (!token)
    return (
      <RequestAccessGlobalWorkflowConsumer>
        {({ widgetProps, site }) => (
          <a
            className={widgetProps.classbtn}
            href={getFormRequestAccessUrl(
              environment,
              (site?.url || widgetProps.popsIdentifier || "").replace(
                "#",
                "%23"
              ),
              widgetProps.sourceUrl ?? window.location.hostname,
              widgetProps.sourceContext ?? "widget-modal"
            )}
          >
            <ButtonModalAccessText />
          </a>
        )}
      </RequestAccessGlobalWorkflowConsumer>
    );

  return (
    <RequestAccessGlobalWorkflowConsumer>
      {({ widgetProps }) => (
        <>
          <button className={widgetProps.classbtn} onClick={handleShow}>
            <ButtonModalAccessText />
          </button>
          {show && (
            <ReactPortal>
              <style type="text/css">{sgBootstrap.inlineStyle}</style>
              <div
                className={`modal modal-top text-left show ${sgBootstrap.className}`}
                onClick={handleModalClick}
                style={{ display: "block" }}
                aria-modal="true"
                role="dialog"
                id="request-access-modal"
              >
                <ModalContentFactory />
              </div>
              <div className="modal-backdrop show" />
            </ReactPortal>
          )}
        </>
      )}
    </RequestAccessGlobalWorkflowConsumer>
  );
};
