import React, { useContext } from "react";
import { RequestAccessGlobalWorkflowContext } from "../../contexts/RequestAccessGlobalWorkflowContext";
import { HeaderModal } from "../modal-common/HeaderModal";
import { getPopsIdentifierOnGlobalContext } from "../../services/getPopsIdentifierOnGlobalContext";
import { requestAccessTranslate } from "../../services/requestAccessTranslate";
import { useWidgetConfiguration } from "@sg-widgets/react-core";
import { getIamUrl } from "../../../common/configuration/services-url";

const getHeaderMessage = (status: string): string => {
  switch (status.toLocaleLowerCase()) {
    case "pending":
      return "You have already requested access to this application through IAM.";
    case "granted":
      return "You have already requested access to this application through POPS.";
    case "prohibited":
      return "You are not authorized to request access to this application/profile in IAM.";
    case "not found":
      return "Service not found.";
    case "error":
      return "An error occured.";
    default:
      return "This service requires an IAM request.";
  }
};

const getBodyMessage = (status: string, popsIdentifier: string): JSX.Element => {
  switch (status.toLocaleLowerCase()) {
    case "not found":
      return (
        <span>
          <p className="mb-3">
            This service requires an IAM request, however IAM cannot find the
            application
          </p>
          <p className="mb-3">
            For more information, please contact:{" "}
            <a href="mailto:EMEA-ROCS-IAM-Analyst">EMEA-ROCS-IAM-Analyst</a>
          </p>
        </span>
      );
    case "error":
      return (
        <p className="mb-3">
          POPS is currently not available.
          <br />
          Please retry later
        </p>
      );
    case "pending":
      return (
        <p className="mb-3">
          You can go to IAM to check the status.
          <br />
          If your request is approved, you will receive a confirmation email in
          the coming days.
        </p>
      );
    case "prohibited":
      return (
        <p className="mb-3">
          Please contact your IAM Analysts by specifying the application and
          profile needed. <br />
          <br />
          Your IAM analysts are displayed in &apos;My Data&apos; section.
        </p>
      );
    case "yet to confirm":
      return (
        <p className="mb-3">
          Your access request has been created in IAM but has not been sent yet.
          <br />
          Please go to IAM, go to the screen &apos;POPS/My access rights&apos; and click
          on Confirm.
        </p>
      );
    case "granted":
      return (
        <p className="mb-3">
          You can go to POPS to check the status.
          <br />
          If your request is approved, you will receive a confirmation email in
          the coming days.
          <br />
          Please contact the dedicated support of the service for more information.
        </p>
      );
    default:
      return (
        <p className="mb-3">
          In IAM, please search for the application{" "}
          <span className="font-weight-bold fw-bold">{popsIdentifier}</span>, complete
          the relevant information then submit your request.
        </p>
      );
  }
};

const getButtonLink = (environment: string, status: string): string => {
  switch (status.toLocaleLowerCase()) {
    case "error":
    case "granted":
      return `${getIamUrl(environment)}/pops`;
    default:
      return `${getIamUrl(environment)}/accesses`;

  }
};

const getButtonMessage = (status: string): string => {
  switch (status.toLocaleLowerCase()) {
    case "error":
    case "granted":
      return "Open Pops";
    default:
      return "Open SG Markets IAM";
  }
};

export const PopsDefaultContent: React.FC<{ status: string }> = ({
  status,
}) => {
  const globalContext = useContext(RequestAccessGlobalWorkflowContext);
  const { environment } = useWidgetConfiguration();

  return (
    <div>
      <HeaderModal />
      <div className="modal-body">
        <p className="text-xlarge fs-3 font-weight-medium fw-medium mb-3">
          {" "}
          {status.toLocaleLowerCase() === "error" && (
            <i className="icon icon-md">error_outline</i>
          )}{" "}
          {getHeaderMessage(status)}
        </p>
          {getBodyMessage(
            status,
            getPopsIdentifierOnGlobalContext(globalContext)
          )}
      </div>
      <div className="modal-footer pt-0">
        <button
          type="button"
          className="btn btn-lg mr-3 text-secondary"
          data-dismiss="modal"
        >
          {requestAccessTranslate(globalContext.lang, "cancel")}
        </button>
        <a
          href={getButtonLink(environment, status)}
          target="_blank"
          rel="noreferrer"
          className={`btn btn-lg ${globalContext.widgetProps.colorSubmitBtn}`}
          data-dismiss="modal"
        >
          {getButtonMessage(status)}
        </a>
      </div>
    </div>
  );
};
