import React, { useEffect } from "react";
import {
  injectWidget,
  WidgetName,
} from "../../../common/services/sgDashboard/widgetDependencyService";
import { RequestAccessGlobalWorkflowConsumer } from "../../contexts/RequestAccessGlobalWorkflowContext";
import { Site } from "../../models/RequestAccessModels";
import { FetchFn } from "@sg-widgets/react-core";
import { useSgBootstrapContext } from "../../../common/components/WithSGBootstrap";
import { inlineStyle } from "./idy-bs5-hack";

export const ButtonIAMAccess: React.FC = () => {
  const { version } = useSgBootstrapContext();

  injectWidget(WidgetName.idyRequestAccess);

  useEffect(() => {
    if (version === 5) {
      loadBs5StyleForIam();
      return () => unloadBs5StyleForIam();
    }
  }, [version]);

  return (
    <RequestAccessGlobalWorkflowConsumer>
      {({ site, widgetProps }) => {
        return (
          <idy-request-access
            selected-resource-id={site?.ressourceId}
            class-btn={widgetProps.classbtn}
            icon-btn-version={widgetProps.useIcon ? 1 : 0}
          />
        );
      }}
    </RequestAccessGlobalWorkflowConsumer>
  );
};

export const canUseIAMWidget = ({
  site,
  fetch,
}: {
  site: Site | undefined;
  fetch: FetchFn | null;
}): boolean => {
  if (!site || !fetch) return false;
  return (
    !!site.ressourceId && !!site.activateIAMAccessWidget
  );
};

// idy-request-access is not compatible with BS5
// so we load custom css for backward compatibility
const bs5StyleId = 'bs5-for-idy-request-access';
const loadBs5StyleForIam = (): void => {
  let style = document.getElementById(bs5StyleId);

  if (style) {
    const count = parseInt(style.getAttribute('data-count') || '1');
    style.setAttribute('data-count', `${count + 1}`);
  } else {
    style = document.createElement('style');
    style.id = bs5StyleId;
    style.innerHTML = inlineStyle;
    style.setAttribute('data-count', '1');

    document.head.appendChild(style);
  }
}

const unloadBs5StyleForIam = (): void => {
  const style = document.getElementById(bs5StyleId);

  if (style) {
    const count = parseInt(style.getAttribute('data-count') || '1');
    if (count > 1) {
      style.setAttribute('data-count', `${count - 1}`);

    } else {
      document.head.removeChild(style)
    }
  }
}
