export type Fetch = (
  input: RequestInfo,
  init?: RequestInit | undefined
) => Promise<Response>;
export type LogError = (message: string, ...optionalParams: string[]) => void;
export type GetAuthorizationToken = () => string | undefined;

export type DownloadedFile = {
  name: string | null;
  content: Blob;
};

const getFileName = (response: Response): string | null => {
  const contentDisposition = response.headers.get("content-disposition");
  if (!contentDisposition) {
    return null;
  }

  const fileNames = contentDisposition
    .split(";")
    .filter((c) => c.indexOf("filename=") >= 0);

  if (fileNames.length > 1) {
    return null;
  }

  const fileNameAttribute = fileNames[0].split("=");
  if (fileNameAttribute.length !== 2) {
    return null;
  }

  return fileNameAttribute[1];
};

export class HttpClient {
  constructor(
    private fetch: Fetch,
    private logError: LogError,
    private getAuthorizationToken?: GetAuthorizationToken,
    private includeCredential?: boolean
  ) { }

  public async get<T>(
    url: string,
    additionalHeaders?: { [key: string]: string },
    encodeSharpChar = false
  ): Promise<T | null> {
    if (!url) {
      return null;
    }

    try {
      const headers = this.getHeaders(additionalHeaders);
      let uri = encodeURI(url);
      if (encodeSharpChar) {
        uri = uri.replace("#", "%23");
      }
      let response = await this.fetch(uri, {
        credentials: this.getRequestCorsCredential(),
        headers,
      });

      response = this.checkStatus(response);
      const responseContent = (await response.json()) as T;
      return responseContent;
    } catch (e) {
      this.logError(
        `An error occured while retrieving: ${url}.\nError: ${this.prettyPrintError(
          e
        )}`
      );
      return null;
    }
  }

  public async getReponse<T>(
    url: string,
    additionalHeaders?: { [key: string]: string }
  ): Promise<{
    success: boolean;
    result?: T;
    status: string;
    statusCode: number;
  } | null> {
    if (!url) {
      return null;
    }

    let responseStatus = 0;
    try {
      const headers = this.getHeaders(additionalHeaders);
      const uri = encodeURI(url);
      const response = await this.fetch(uri, {
        credentials: this.getRequestCorsCredential(),
        headers,
      });
      responseStatus = response.status;
      const responseContent = (await response.json()) as T;
      return {
        result: responseContent,
        success: response.status >= 200 && response.status < 300,
        status: response.statusText,
        statusCode: responseStatus,
      };
    } catch (e) {
      this.logError(`An error occured while retrieving: ${url}.\nError: ${this.prettyPrintError(e)}`);
      const errorMessage = (e as Error)?.message;
      return {
        success: false,
        status: `${errorMessage} (${responseStatus})`,
        statusCode: responseStatus,
      };
    }
  }

  public async getBlob(
    url: string,
    additionalHeaders?: { [key: string]: string }
  ): Promise<DownloadedFile> {
    try {
      const headers = this.getHeaders(additionalHeaders);
      let response = await this.fetch(url, {
        cache: "default",
        credentials: this.getRequestCorsCredential(),
        headers,
        method: "GET",
      });

      response = this.checkStatus(response);
      const name = getFileName(response);
      const content = await response.blob();

      return {
        name,
        content,
      };
    } catch (e) {
      this.logError(
        `[Error] An error occured while sending POST with content: ${url}. Error: ${this.prettyPrintError(
          e
        )}`
      );
      throw e;
    }
  }

  public async post<TOuput>(
    url: string,
    bodyContent: object,
    additionalHeaders?: { [key: string]: string }
  ): Promise<TOuput | null> {
    if (!url) {
      return null;
    }

    try {
      const headers = this.getHeaders(additionalHeaders);
      let response = await this.fetch(url, {
        body: JSON.stringify(bodyContent),
        cache: "default",
        credentials: this.getRequestCorsCredential(),
        headers,
        method: "POST",
      });

      response = this.checkStatus(response);
      const responseContent = (await response.json()) as TOuput;
      return responseContent;
    } catch (e) {
      this.logError(
        `[Error] An error occured while sending POST: ${url}. Error: ${this.prettyPrintError(
          e
        )}`
      );
      return null;
    }
  }

  public async postReponse<TOuput>(
    url: string,
    bodyContent: object,
    additionalHeaders?: { [key: string]: string }
  ): Promise<{
    success: boolean;
    result?: TOuput;
    status: string;
    statusCode: number;
  } | null> {
    if (!url) {
      return null;
    }

    let responseStatus = 0;
    try {
      const headers = this.getHeaders(additionalHeaders);
      const uri = encodeURI(url);
      const response = await this.fetch(uri, {
        body: JSON.stringify(bodyContent),
        cache: "default",
        credentials: this.getRequestCorsCredential(),
        headers,
        method: "POST",
      });
      responseStatus = response.status;
      const responseContent = (await response.json()) as TOuput;
      return {
        result: responseContent,
        success: response.status >= 200 && response.status < 300,
        status: response.statusText,
        statusCode: responseStatus,
      };
    } catch (e) {
      this.logError(`An error occured while retrieving: ${url}.\nError: ${this.prettyPrintError(e)}`);
      const errorMessage = (e as Error)?.message;
      return {
        success: false,
        status: `${errorMessage} (${responseStatus})`,
        statusCode: responseStatus,
      };
    }
  }

  public async postBlob(
    url: string,
    bodyContent: object,
    additionalHeaders?: { [key: string]: string }
  ): Promise<DownloadedFile> {
    try {
      const headers = this.getHeaders(additionalHeaders);
      let response = await this.fetch(url, {
        body: JSON.stringify(bodyContent),
        cache: "default",
        credentials: this.getRequestCorsCredential(),
        headers,
        method: "POST",
      });

      response = this.checkStatus(response);
      const name = getFileName(response);
      const content = await response.blob();

      return {
        name,
        content,
      };
    } catch (exception) {
      this.logError(
        `[Error] An error occured while sending POST with content: ${url}. Error: ${this.prettyPrintError(
          exception
        )}`
      );
      throw exception;
    }
  }

  private getHeaders(additionalHeaders?: { [key: string]: string }): {
    [key: string]: string;
  } {
    const defaultHeaders = {
      accept: "application/json",
      "content-type": "application/json",
    };

    const headers: { [key: string]: string } = {
      ...defaultHeaders,
      ...additionalHeaders,
    };

    if (this.getAuthorizationToken) {
      const accessToken = this.getAuthorizationToken();
      if (accessToken) {
        headers.authorization = accessToken;
      }
    }

    return headers;
  }

  private checkStatus(response: Response): Response {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      this.logError(response.statusText);
      const error: Error = new Error(response.statusText);
      throw error;
    }
  }

  private prettyPrintError(error: Error | unknown): string {
    if (error) {
      return JSON.stringify(error, Object.getOwnPropertyNames(error));
    }

    return "[Error] An unknown error occured";
  }

  private getRequestCorsCredential(): RequestCredentials {
    if (this.includeCredential === false) {
      return "omit";
    }
    return "include";
  }
}
