import { FetchFn, SgConnectFetchResult } from "@sg-widgets/react-core";
import { defaultSite, Site } from "../models/RequestAccessModels";
import { HttpClient } from "../../common/api/httpClient";
import { getApiRequestAccessUrl } from "../../common/configuration/services-url";
import { WidgetConfiguration } from "@sg-widgets/shared-core";
import { WidgetProps } from "../models/RequestAccessWidgetProps";
import {
  PopsAccessResult,
  PopsApplicationProfileResult,
} from "../models/RequestAccessPopsModels";
import { getSgConnectFetchErrorMessageByStatus } from "../../common/message/fetch-error-message";

export const getService = (
  widgetProps: WidgetProps,
  fetch: FetchFn,
  config: WidgetConfiguration
): Promise<Site> =>
  new Promise(resolve => {
    if (fetch) {
      const http = new HttpClient(fetch, config.error, undefined, false);
      const encodedUrl = widgetProps.url ? `&url=${btoa(widgetProps.url)}` : '';
      const ressourceId = widgetProps.ressourceId || widgetProps.site?.ressourceId || '';
      const baseUrl = getApiRequestAccessUrl(config.environment);
      http
        .getReponse<Site>(`${baseUrl}/v1/services?ressourceId=${ressourceId}${encodedUrl}`)
        .then(site => {
          if (site?.success && site?.result) {
            resolve(site.result);
          } else {
            resolve(defaultSite);
          }
        });
      return;
    } else {
      resolve(defaultSite);
    }
  });

export const getPopsAccessResult = (
  popsIdentifier: string,
  sgConnectFetch: SgConnectFetchResult,
  config: WidgetConfiguration
): Promise<PopsAccessResult> =>
  new Promise((resolve, reject) => {
    if (sgConnectFetch.fetch) {
      const http = new HttpClient(sgConnectFetch.fetch, config.error, undefined, false);
      const baseUrl = getApiRequestAccessUrl(config.environment);
      http
        .getReponse<PopsAccessResult>(`${baseUrl}/v1/pops/access?popsIdentifier=${popsIdentifier}`)
        .then(site => {
          if (site?.success && site?.result?.resultStatus === "OK") {
            resolve(site.result);
          } else {
            reject(site?.result?.errorMessage || "error access");
          }
        })
        .catch(() => {
          reject("error access");
        });
    } else {
      reject(getSgConnectFetchErrorMessageByStatus(sgConnectFetch.status));
    }
  });

export const getPopsApplicationProfileResult = (
  popsIdentifier: string,
  sgConnectFetch: SgConnectFetchResult,
  config: WidgetConfiguration
): Promise<PopsApplicationProfileResult> =>
  new Promise((resolve, reject) => {
    if (sgConnectFetch.fetch) {
      const http = new HttpClient(sgConnectFetch.fetch, config.error, undefined, false);
      const baseUrl = getApiRequestAccessUrl(config.environment);
      http
        .getReponse<PopsApplicationProfileResult>(`${baseUrl}/v1/pops/applicationProfiles?popsIdentifier=${popsIdentifier}`)
        .then(fechResult => {
          if (
            fechResult?.success &&
            fechResult?.result?.resultStatus === "OK"
          ) {
            resolve(fechResult.result);
          } else {
            reject(
              fechResult?.result?.errorMessage || "error application profile"
            );
          }
        })
        .catch(() => {
          reject("error application profile");
        });
    } else {
      reject(getSgConnectFetchErrorMessageByStatus(sgConnectFetch.status));
    }
  });
