import React from "react";
import { HeaderModal } from "../modal-common/HeaderModal";
import { Loading } from "../../../common/components/Loading";

export const LoadingContent: React.FC = () => (
  <div>
    <HeaderModal />
    <div className="modal-body">
      <Loading />
    </div>
    <div className="modal-footer pt-0" />
  </div>
);
