import { WidgetName } from '../services/sgDashboard/widgetDependencyService';
import { Environment } from './environment'

const getArclabPickerComponentUrl = (environment: string): string => {
    switch (environment as Environment) {
        case "production":
            return `https://shared.sgmarkets.com/arclab-sg-dashboard/prd/components/${WidgetName.arclabPicker}/v1/${WidgetName.arclabPicker}.js`;
        default:
            return `https://shared.sgmarkets.com/arclab-sg-dashboard/uat/components/${WidgetName.arclabPicker}/v1/${WidgetName.arclabPicker}.js`;
    }
};

const getArclabSelectComponentUrl = (environment: string): string => {
    switch (environment as Environment) {
        case "production":
            return `https://shared.sgmarkets.com/arclab-sg-dashboard/prd/components/${WidgetName.arclabSelect}/v1/${WidgetName.arclabSelect}.js`;
        default:
            return `https://shared.sgmarkets.com/arclab-sg-dashboard/uat/components/${WidgetName.arclabSelect}/v1/${WidgetName.arclabSelect}.js`;
    }
};

const getHighchartsComponentUrl = (environment: string): string => {
    switch (environment as Environment) {
        case "production":
            return `https://shared.sgmarkets.com/widgets/${WidgetName.sgwtHighcharts}/v4/${WidgetName.sgwtHighcharts}.js`;
        default:
            return `https://shared-uat.fr.world.socgen/widgets/${WidgetName.sgwtHighcharts}/v4/${WidgetName.sgwtHighcharts}.js`;
    }
};

const getIdyRequestAccessUrl = (environment: string): string => {
    switch (environment as Environment) {
        case "production":
            return `https://shared.fr.world.socgen/sgidentity/v1/idy-request-access.js`;
        default:
            return `https://shared-uat.fr.world.socgen/sgidentity/v1/idy-request-access.js`;
    }
};

export const getComponentUrl = (widgetName: WidgetName, environment: string): string => {
    switch (widgetName) {
        case WidgetName.arclabPicker:
            return getArclabPickerComponentUrl(environment);
        case WidgetName.arclabSelect:
            return getArclabSelectComponentUrl(environment);
        case WidgetName.sgwtHighcharts:
            return getHighchartsComponentUrl(environment);
        case WidgetName.idyRequestAccess:
            return getIdyRequestAccessUrl(environment);
    }
}
