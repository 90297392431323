import React from "react";
import { WithCss } from "@sg-widgets/react-core";

import styles from "./RequestAccessCheckboxes.scss"

type RequestAccessOption = { key: string, label: string };

type RequestAccessCheckboxesProps = {
    options: RequestAccessOption[] | undefined
    selectedKeys: string[],
    onChange: (key: string) => void;
    singleSelection?: boolean;
};

export const RequestAccessCheckboxes: React.FC<RequestAccessCheckboxesProps> = ({ options, selectedKeys, onChange, singleSelection = false }) => (
    <div className="mb-3">
        <WithCss styles={styles} />
        {options && options.map(p => {
            const isSelected = selectedKeys.indexOf(p.key) >= 0;
            return (
                <div
                    key={p.key}
                    className={`custom-control form-check w-100 mt-2 pl-0 ${singleSelection ? 'custom-radio' : 'custom-checkbox'}`}
                >
                    <input
                        key={p.key}
                        type={singleSelection ? 'radio' : 'checkbox'}
                        className='custom-control-input form-check-input ms-3 ml-3'
                        checked={isSelected}
                        onChange={() => onChange(p.key)}
                        id={`request-access-checkboxes-${p.key}`}
                    />
                    <label
                        className={`custom-control-label fix-bs4 form-check-label font-weight-medium fw-medium pl-3 py-3 w-100 border ${isSelected ? 'border-black' : 'border'} ${styles.locals["fix-bs4"]}`}
                        htmlFor={`request-access-checkboxes-${p.key}`}
                    >
                        <span className="ps-3 pl-4">{p.label}</span>
                    </label>
                </div>
            );
        })}
    </div>
);
