import React from "react";

type Props = {
  message: string;
  title: string;
  children?: React.ReactNode;
};

export const SuccessModal: React.FC<Props> = ({ title, message, children }) => (
  <div className="modal-dialog">
    <div className="modal-content shadow-max border border-success">
      <div className="modal-header pt-4 pb-0">
        <div className="d-flex align-items-center flex-column w-100">
          <div
            className="rounded-circle bg-success-alpha-md bg-success bg-opacity-10 d-flex align-items-center justify-content-center mb-4"
            style={{ width: "3rem", height: "3rem" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                fill="#02cc53"
              />
            </svg>
          </div>
          <h3 className="modal-title row text-center text-success mb-2">
            {title}
          </h3>
        </div>
      </div>
      <p className="text-center pt-4 px-5 mb-5">{message}</p>
      {children && (!Array.isArray(children) || children.length) ? (
        <div className="modal-body pb-0">{children}</div>
      ) : null}
      <div className="modal-footer d-flex justify-content-center pb-4 pt-0">
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          className="btn btn-lg sgbs-btn-default btn-default"
        >
          Done
        </button>
      </div>
    </div>
  </div>
);
