import React, { useContext } from "react";
import { HeaderModal } from "../modal-common/HeaderModal";
import { PopsStepper } from "../step/PopsStepper";
import { RequestAccessPopsWorkflowContext } from "../../contexts/RequestAccessPopsWorkflowContext";
import { RequestAccessGlobalWorkflowContext } from "../../contexts/RequestAccessGlobalWorkflowContext";
import { getPopsIdentifierOnGlobalContext } from "../../services/getPopsIdentifierOnGlobalContext";
import {
  PopsContentInfo,
  defaultPopsCommentName,
} from "../../models/RequestAccessPopsModels";

const ProfileCard = (props: { name: string; profileKey: string }): JSX.Element => {
  const popsContext = useContext(RequestAccessPopsWorkflowContext);
  return (
    <div
      key={`profile-${props.profileKey}`}
      className="row align-items-center border mx-0 mb-2 p-3"
    >
      <div className="col-10">
        <p className="mb-0 font-weight-medium fw-medium">{props.name}</p>
      </div>
      <div className="col-2">
        <span className="float-right">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              popsContext.changeWorkflowState("PROFILE");
            }}
          >
            Edit
          </a>
        </span>
      </div>
    </div>
  );
};

const Card = (props: {
  name: string;
  description: string;
  classDescription?: string;
  cardKey: string;
}): JSX.Element => {
  const popsContext = useContext(RequestAccessPopsWorkflowContext);
  return (
    <div
      key={`card-${props.cardKey}`}
      className="row align-items-center border mx-0 mb-2 p-3"
    >
      <div className="col-10">
        <p className="text-secondary mb-2 font-weight-medium fw-medium">{props.name}</p>
        <p className="text-large fs-4 mb-0 font-weight-medium fw-medium">
          <span className={props.classDescription || ""}>
            {props.description}
          </span>
        </p>
      </div>
      <div className="col-2">
        <span className="float-right">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              popsContext.changeWorkflowState("INFO");
            }}
          >
            Edit
          </a>
        </span>
      </div>
    </div>
  );
};

const changeCommentName = (name: string): string => {
  if (name.toLocaleLowerCase() === defaultPopsCommentName) {
    return "Comment";
  }
  return name;
};

function entries<T>(obj: { [key: string]: T }): Array<[string, T]> {
  const ownProps = Object.keys(obj);
  let i = ownProps.length;
  const resArray = new Array(i);
  while (i--) {
    resArray[i] = [ownProps[i], obj[ownProps[i]]];
  }
  return resArray;
}

const mapToPopsParams = (popsInfo: PopsContentInfo): {
  name: string;
  values: string[];
}[] => {
  return entries(popsInfo)
    .filter(
      ([key, value]) =>
        value &&
        value.length > 0 &&
        key.toLocaleLowerCase() !== defaultPopsCommentName
    )
    .map(keyValue => ({
      name: keyValue[0],
      values: keyValue[1],
    }));
};

const getCommentParams = (popsInfo: PopsContentInfo): string | undefined => {
  const comment = entries(popsInfo)
    .filter(
      ([key, value]) =>
        value &&
        value.length === 1 &&
        key.toLocaleLowerCase() === defaultPopsCommentName
    )
    .map(keyValue => keyValue[1][0]);
  if (comment && comment.length > 0 && comment[0].length > 0) {
    return comment[0];
  }
};

export const PopsSummaryContent: React.FC = () => {
  const popsContext = useContext(RequestAccessPopsWorkflowContext);
  const globalContext = useContext(RequestAccessGlobalWorkflowContext);

  return (
    <>
      <HeaderModal>
        <PopsStepper current={3} />
      </HeaderModal>
      <div className="modal-body" style={{ maxHeight: "50vh" }}>
        <div>
          <p className="mb-3">Selected Profile:</p>
          {popsContext.selectedProfile.map(profile => (
            <ProfileCard key={profile} profileKey={profile} name={profile} />
          ))}
        </div>
        <p className="my-3 pt-3">Additional Information:</p>
        <div>
          {entries(popsContext.selectedInfo).map((p: [string, string[]]) => (
            <Card
              key={p[0]}
              cardKey={p[0]}
              name={changeCommentName(p[0])}
              description={p[1].join(", ") || "No Entry"}
              classDescription={p[1].join(", ") ? "" : "font-italic fst-italic"}
            />
          ))}
        </div>
      </div>
      <div className="modal-footer">
        <fieldset className="d-flex w-100">
          <button
            type="button"
            className="btn btn-icon-text btn-lg btn-outline-primary"
            onClick={() => popsContext.changeWorkflowState("INFO")}
          >
            <i className={`icon icon-sm`}>keyboard_arrow_left</i> Back
          </button>
          <div className="flex-grow-1" />
          <button
            type="button"
            className={`btn py-2 px-3 ${globalContext.widgetProps.colorSubmitBtn}`}
            onClick={() => {
              const popsIdentifier = getPopsIdentifierOnGlobalContext(
                globalContext
              );
              globalContext.sendRequestAccess("/pops", {
                serviceName: popsIdentifier,
                profiles: popsContext.selectedProfile,
                params: mapToPopsParams(popsContext.selectedInfo),
                comments: getCommentParams(popsContext.selectedInfo),
              });
              popsContext.changeWorkflowState("SUBMIT");
            }}
          >
            Submit request
          </button>
        </fieldset>
      </div>
    </>
  );
};
