// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pph17JWrIvMYSkIAy911aw\\=\\=::before{top:1.25rem;left:initial}.pph17JWrIvMYSkIAy911aw\\=\\=::after{top:1.25rem;left:initial}", "",{"version":3,"sources":["webpack://./src/sgmarkets-request-access/components/content-request-sgmarkets/RequestAccessCheckboxes.scss"],"names":[],"mappings":"AACI,oCACI,WAAA,CACA,YAAA,CAGJ,mCACI,WAAA,CACA,YAAA","sourcesContent":[".fix-bs4 {\r\n    &::before {\r\n        top: 1.25rem;\r\n        left: initial;\r\n    }\r\n\r\n    &::after {\r\n        top: 1.25rem;;\r\n        left: initial;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fix-bs4": "pph17JWrIvMYSkIAy911aw=="
};
export default ___CSS_LOADER_EXPORT___;
