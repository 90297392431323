import { Loading } from "../../../common/components/Loading";
import React from "react";
import { HeaderModal } from "../modal-common/HeaderModal";
import { PopsStepper } from "../step/PopsStepper";

export const PopsSubmitContent: React.FC = () => (
  <div>
    <HeaderModal>
      <PopsStepper current={4} />
    </HeaderModal>
    <div className="modal-body">
      <h4 className="text-center">Your request is being processed</h4>
      <Loading />
    </div>
    <div className="modal-footer" />
  </div>
);
