import { useHttpClientHelper } from "../../common/api/httpClientHelper";
import { getApiRequestAccessUrl } from "../../common/configuration/services-url";
import { RequestUserResponse } from "../models/RequestUserResponse";

type ContactServiceType = {
    getRequestUserByIcId: (icId: string) => Promise<RequestUserResponse>;
}

export const useRequestUserService = (): ContactServiceType => {
    return useHttpClientHelper([], (httpClientHelper, config) => {
        const baseUrl = getApiRequestAccessUrl(config.environment);

        const getRequestUserByIcId = async (icId: string): Promise<RequestUserResponse> => {
            const url = `${baseUrl}/v1/request-users/` + icId;            
            return await httpClientHelper.get(url);
        }

        return {
            getRequestUserByIcId,
        }
    }, false)
}