import React from "react";
import { RequestAccessGlobalWorkflowConsumer, RequestAccessGlobalWorkflowState } from "../../contexts/RequestAccessGlobalWorkflowContext";
import { getPopsIdentifierOnGlobalContext } from "../../services/getPopsIdentifierOnGlobalContext";

const getSiteHeader = (
  globalContext: Readonly<RequestAccessGlobalWorkflowState>,
): string => {  
  const { site } = globalContext;
  const popsIdentifier: string = getPopsIdentifierOnGlobalContext(globalContext);
  const requestMessage = popsIdentifier ? "Pops access request" : "Request access";

  if (site || popsIdentifier) {
    const siteName = `: ${(site && site.name) || popsIdentifier}`;
    return `${requestMessage} ${siteName}`;
  }
  return requestMessage;
};

export const HeaderModal: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const hasChildren =
    !!children &&
    (!Array.isArray(children) || !!children.length);
  return (
    <div>
      <div className={`modal-header ${(hasChildren && "pb-0") || ""}`}>
        <h3 className={`modal-title ${(hasChildren && "mb-4") || ""}`}>
          <RequestAccessGlobalWorkflowConsumer>
            {(globalContext) =>
              getSiteHeader(globalContext)
            }
          </RequestAccessGlobalWorkflowConsumer>
        </h3>
        <button
          type="button"
          className="close icon btn btn-xl btn-icon btn-flat-secondary align-self-start m-0 p-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          close
        </button>
      </div>
      {hasChildren ? (
        <div className="d-none d-sm-block mb-3 p-3">{children}</div>
      ) : null}
      <div className="d-block d-sm-none">
        <div className="mb-3" />
      </div>
    </div>
  );
};
