import React, { ChangeEvent } from "react";
import { RequestAccessGlobalWorkflowConsumer } from "../../contexts/RequestAccessGlobalWorkflowContext";
import { requestAccessTranslate } from "../../services/requestAccessTranslate";
import { HeaderModal } from "../modal-common/HeaderModal";

export const CommentContent: React.FC = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("");

  const updateText = React.useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setText(event.target.value);
    },
    [setText]
  );

  return (
    <RequestAccessGlobalWorkflowConsumer>
      {({ widgetProps, site, sendRequestAccess, lang }) => (
        <div>
          <HeaderModal />
          <div className="modal-body">
            <p className="mb-3">
              Thank you for your interest. Please let us know if you have any
              comment regarding this request and we will be in touch shortly.
            </p>
            <textarea
              style={{ resize: "none" }}
              className="form-control"
              placeholder="Enter your comment here"
              rows={6}
              onChange={updateText}
              value={text}
            >
              {text}
            </textarea>
          </div>
          <div className="modal-footer pt-0">
            <button
              type="button"
              className="btn btn-lg mr-3 text-secondary"
              data-dismiss="modal"
            >
              {requestAccessTranslate(lang, "cancel")}
            </button>
            <button
              type="button"
              className={`btn btn-lg ${widgetProps.colorSubmitBtn}`}
              onClick={() => {
                setLoading(true);
                sendRequestAccess("/", {
                  serviceKey: site?.serviceKey || "",
                  comment: text,
                  sourceUrl: widgetProps.sourceUrl ?? window.location.hostname,
                  sourceContext: widgetProps.sourceContext ?? "comment-button",
                });
              }}
              disabled={isLoading}
            >
              {requestAccessTranslate(lang, "sendRequest")}
            </button>
          </div>
        </div>
      )}
    </RequestAccessGlobalWorkflowConsumer>
  );
};
