import { Action, createErrorAction, createSuccessAction } from '../reducers/RequestAccessGlobalWorkflowAction';
import { RequestData, ResponseType } from "../models/RequestAccessModels";
import { PopsError } from "../models/RequestAccessPopsModels";

export const transformGlobalRequestAccessResultToEndModalDispatch = (
  content: RequestData,
  response: ResponseType | null,
  endpoint: string,
  emitRequestSent: (data: RequestData) => void
): Action => {
  let successMessage =
    "Your request has been sent to the relevant team. if it is validated, you will receive a confirmation email in the coming days.";
  const isPopsRequest = endpoint.indexOf("pops") !== -1;
  let errorMessage = "";
  if (!response) {
    errorMessage = "An error has occurred, please retry later";
  } else if (!response.success) {
    if (response.statusCode === 403) {
      errorMessage = `You are not authorized to request access to this application/profile in IAM.
          Please contact your IAM Analyst if you want to request access to this application/profile.
          Your IAM analysts are displayed in contacts tab in IAM.`;
    } else if (
      response.statusCode === 409 &&
      (response.result as PopsError).message ===
      "Resource already exists for user"
    ) {
      errorMessage = `You request access is already created in IAM.
          Please go to IAM to check the status.
          If it is validated, you will receive a confirmation email in the coming days.`;
    } else {
      errorMessage =
        ((response.result as PopsError) &&
          (response.result as PopsError).message) ||
        "An error has occurred, please retry later";
    }
  } else if (response.success) {
    const res = response.result;
    if (res && isPopsRequest) {
      successMessage =
        "Your access request has been created in IAM and the validation process is in progress. If it is validated, you will receive a confirmation email in the coming days.";
    }
    emitRequestSent(content);
  }
  if (errorMessage) {
    return createErrorAction(errorMessage);
  }
  return createSuccessAction(successMessage);
};
