import React, { useContext } from "react";
import {
  RequestAccessGlobalWorkflowConsumer,
  RequestAccessGlobalWorkflowContext,
} from "../../contexts/RequestAccessGlobalWorkflowContext";
import {
  useUserIsInternal,
  useUserExistsInPops,
  useUserContactId,
} from "../../services/SgConnectService";
import { NotFoundContent } from "./NotFoundContent";
import { BusinessJustificationContent } from "./BusinessJustificationContent";
import { LoadingContent } from "./LoadingContent";
import { CommentContent } from "./CommentContent";
import { RequestAccessPopsWorkflowProvider } from "../../contexts/RequestAccessPopsWorkflowContext";
import { getPopsIdentifierOnGlobalContext } from "../../services/getPopsIdentifierOnGlobalContext";
import { ModalRequestPopsFactory } from "../content-request-pops/ModalRequestPopsFactory";

export const ModalRequestSgMarketsFactory: React.FC = () => {
  const isInternalUser = useUserIsInternal();
  const isExistsInPopsUser = useUserExistsInPops();
  const icIdUser = useUserContactId();
  const globalContext = useContext(RequestAccessGlobalWorkflowContext);
  const popsIdentifier = getPopsIdentifierOnGlobalContext(globalContext);

  return (
    <RequestAccessPopsWorkflowProvider popsIdentifier={popsIdentifier}>
      <RequestAccessGlobalWorkflowConsumer>
        {({ site, loading, widgetProps: { icid } }) => {
          if (loading) return <LoadingContent />;
          if (site) {
            if (isInternalUser) {
              if (popsIdentifier && (!icid || icid === icIdUser)) {
                if (isExistsInPopsUser) {
                  return <ModalRequestPopsFactory />;
                }
                // User dont exist in Pops
                return <BusinessJustificationContent />;
              }
              // Business Justification
              return <BusinessJustificationContent />;
            }
            // Comment
            return <CommentContent />;
          } else if (popsIdentifier && isInternalUser) {
            if (isExistsInPopsUser) {
              // Pops
              return <ModalRequestPopsFactory />;
            }
            // User dont exist in Pops
            return <BusinessJustificationContent />;
          }
          // Not found
          return <NotFoundContent />;
        }}
      </RequestAccessGlobalWorkflowConsumer>
    </RequestAccessPopsWorkflowProvider>
  );
};
