import React, { useEffect, useContext, Dispatch } from "react";
import {
  useSgConnectFetch,
  useWidgetConfiguration,
} from "@sg-widgets/react-core";
import { RequestAccessGlobalWorkflowContext } from "./RequestAccessGlobalWorkflowContext";
import {
  getPopsAccessResult,
  getPopsApplicationProfileResult,
} from "../services/requests";
import { RequestAccessPopsWorkflowReducerStore } from "../models/RequestAccessStore";
import { PopsAdditionalInfo, PopsProfile } from "../models/RequestAccessPopsModels";

type RequestAccessPopsWorkflowType = {
  changeWorkflowState: (page: string) => void;
  updateSelectedProfiles: (selectedProfiles: string[]) => void;
  updateSelectedAdditonalInformation: (selectedInfo: { [key: string]: string[] }) => void;
}

type RequestAccessPopsWorkflowReducerType = RequestAccessPopsWorkflowType & {
  state: RequestAccessPopsWorkflowReducerStore;
  dispatch: Dispatch<Action>;
}

type RequestAccessPopsWorkflowProviderState = RequestAccessPopsWorkflowReducerStore & RequestAccessPopsWorkflowType;

const providerInitialState = {
  workflow: "INIT",
  selectedProfile: [],
  selectedInfo: {},
  popsProfile: [],
  popsAdditionalInfo: [],
  loadingAccess: true,
  loadingProfile: true,
  status: "loading",
  needsProfile: true,
  needsAnalyticalParam: true,
  comment: {
    description: "",
    required: false,
  },
} as RequestAccessPopsWorkflowReducerStore;

const initialState = {} as RequestAccessPopsWorkflowProviderState;

type Action = {
  type:
  | "error"
  | "updateApplicationProfileResult"
  | "updateAccessResult"
  | "loadingPopsInfo"
  | "reset"
  | "updateWorkflowState"
  | "updateSelectedProfiles"
  | "updateSelectedAdditonalInformation";
  payload: AccessResultPayload | SelectedAdditonalInformationPayload | ApplicationProfilePayload | string | string[] | null;
};

type AccessResultPayload = { status: string; commentDescription: string; commentRequired: boolean; needsProfile: boolean; needsAdditionalInfo: boolean };

type SelectedAdditonalInformationPayload = { [key: string]: string[] };

type ApplicationProfilePayload = { additionalInfo: PopsAdditionalInfo[]; profile: PopsProfile[] };

type RequestAccessPopsWorkflowState = Readonly<
  RequestAccessPopsWorkflowProviderState
>;

const context = React.createContext<RequestAccessPopsWorkflowState>(
  initialState
);

const { Provider, Consumer } = context;

const requestAccessPopsWorkflowReducer = (
  state: RequestAccessPopsWorkflowReducerStore,
  action: Action
): RequestAccessPopsWorkflowReducerStore => {
  const accessResultPayload = action.payload as AccessResultPayload
  const applicationProfilePayload = action.payload as ApplicationProfilePayload

  switch (action.type) {
    case "updateAccessResult":
      return {
        ...state,
        status: accessResultPayload.status,
        comment: {
          description: accessResultPayload.commentDescription || "Comment",
          required: accessResultPayload.commentRequired,
        },
        needsProfile: accessResultPayload.needsProfile,
        needsAnalyticalParam: accessResultPayload.needsAdditionalInfo,
        loadingAccess: false,
      };
    case "error":
      return {
        ...state,
        status: "error",
        loadingAccess: false,
        loadingProfile: false,
      };
    case "updateSelectedAdditonalInformation":
      return {
        ...state,
        selectedInfo: action.payload as SelectedAdditonalInformationPayload,
      };
    case "updateApplicationProfileResult":
      return {
        ...state,
        popsAdditionalInfo: [...applicationProfilePayload.additionalInfo],
        popsProfile: applicationProfilePayload.profile,
        loadingProfile: false,
      };
    case "updateWorkflowState":
      return { ...state, workflow: action.payload as string };
    case "updateSelectedProfiles":
      return { ...state, selectedProfile: action.payload as string[] };
    default:
      break;
  }
  return state;
};

export const usePopsWorkflowReducer = (): RequestAccessPopsWorkflowReducerType => {
  const [state, dispatch] = React.useReducer(
    requestAccessPopsWorkflowReducer,
    providerInitialState
  );

  const changeWorkflowState = React.useCallback(
    (page: string) => {
      dispatch({ type: "updateWorkflowState", payload: page });
    },
    [dispatch]
  );

  const updateSelectedProfiles = React.useCallback(
    (selectedProfiles: string[]) => {
      dispatch({ type: "updateSelectedProfiles", payload: selectedProfiles });
    },
    [dispatch]
  );

  const updateSelectedAdditonalInformation = React.useCallback(
    (selectedInfo: { [key: string]: string[] }) => {
      dispatch({
        type: "updateSelectedAdditonalInformation",
        payload: selectedInfo,
      });
    },
    [dispatch]
  );

  return {
    state,
    dispatch,
    changeWorkflowState,
    updateSelectedProfiles,
    updateSelectedAdditonalInformation,
  };
};

const RequestAccessPopsWorkflowProvider: React.FC<{
  popsIdentifier: string;
  children?: React.ReactNode;
}> = props => {
  const { popsIdentifier } = props;
  const sgConnectFetch = useSgConnectFetch(["openid", "profile"]);
  const config = useWidgetConfiguration();
  const globalContext = useContext(RequestAccessGlobalWorkflowContext);
  const {
    state,
    dispatch,
    changeWorkflowState,
    updateSelectedProfiles,
    updateSelectedAdditonalInformation,
  } = usePopsWorkflowReducer();
  useEffect(() => {
    let isMounted = true;
    if (popsIdentifier) {
      if (!globalContext.site) {
        dispatch({ type: "updateWorkflowState", payload: "PROFILE" });
      }
      dispatch({ type: "loadingPopsInfo", payload: null });

      getPopsAccessResult(popsIdentifier, sgConnectFetch, config)
        .then(popAccess => {
          if (isMounted)
            dispatch({ type: "updateAccessResult", payload: popAccess });
        })
        .catch(error => {
          if (isMounted) dispatch({ type: "error", payload: error });
        });

      getPopsApplicationProfileResult(popsIdentifier, sgConnectFetch, config)
        .then(popsProfile => {
          if (isMounted)
            dispatch({
              type: "updateApplicationProfileResult",
              payload: popsProfile,
            });
        })
        .catch(error => {
          if (isMounted) dispatch({ type: "error", payload: error });
        });
    }
    return () => {
      isMounted = false;
    };
  }, [popsIdentifier]);

  return (
    <Provider
      value={{
        workflow: state.workflow,
        selectedProfile: state.selectedProfile,
        selectedInfo: state.selectedInfo,
        popsProfile: state.popsProfile,
        popsAdditionalInfo: state.popsAdditionalInfo,
        loadingAccess: state.loadingAccess,
        loadingProfile: state.loadingAccess,
        status: state.status,
        needsProfile: state.needsProfile,
        needsAnalyticalParam: state.needsAnalyticalParam,
        comment: state.comment,
        changeWorkflowState,
        updateSelectedProfiles,
        updateSelectedAdditonalInformation,
      }}
    >
      {props.children}
    </Provider>
  );
};

export {
  RequestAccessPopsWorkflowProvider,
  Consumer as RequestAccessPopsWorkflowConsumer,
  context as RequestAccessPopsWorkflowContext,
};
