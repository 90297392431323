import { useMemo } from "react";
import { DownloadedFile, HttpClient } from "./httpClient";
import {
    useWidgetConfiguration,
    useSgConnectFetch,
    SgConnectFetchResult,
} from "@sg-widgets/react-core";
import { WidgetConfiguration } from "@sg-widgets/shared-core";
import { getSgConnectFetchErrorMessageByStatus } from "../message/fetch-error-message";

interface HttpClientHelper {
    get: <TOuput>(url: string, additionalHeaders?: { [key: string]: string }) => Promise<TOuput>;
    post: <TOuput>(url: string, bodyContent: object, additionalHeaders?: { [key: string]: string }) => Promise<TOuput>;
    postBlob: (url: string, bodyContent: object, additionalHeaders?: { [key: string]: string }) => Promise<DownloadedFile>;
}

const getHttpClientHelper = (sgConnectFetch: SgConnectFetchResult, config: WidgetConfiguration, includeCredential?: boolean): HttpClientHelper => {
    const httpClient = sgConnectFetch.fetch ? new HttpClient(sgConnectFetch.fetch, config.error, undefined, includeCredential) : null

    // TODO wrap other httpclient methods
    const get = async <TOuput>(
        url: string,
        additionalHeaders?: { [key: string]: string }
    ): Promise<TOuput> => {
        if (httpClient) {
            return (await httpClient.get(url, additionalHeaders)) ?? Promise.reject("Unknown error");
        } else {
            return Promise.reject(getSgConnectFetchErrorMessageByStatus(sgConnectFetch.status))
        }
    }

    const post = async <TOuput>(
        url: string,
        bodyContent: object,
        additionalHeaders?: { [key: string]: string }
    ): Promise<TOuput> => {
        if (httpClient) {
            return (await httpClient.post(url, bodyContent, additionalHeaders)) ?? Promise.reject("Unknown error");
        } else {
            return Promise.reject(getSgConnectFetchErrorMessageByStatus(sgConnectFetch.status))
        }
    }

    const postBlob = async (
        url: string,
        bodyContent: object,
        additionalHeaders?: { [key: string]: string }
    ): Promise<DownloadedFile> => {
        if (httpClient) {
            return (await httpClient.postBlob(url, bodyContent, additionalHeaders)) ?? Promise.reject("Unknown error");
        } else {
            return Promise.reject(getSgConnectFetchErrorMessageByStatus(sgConnectFetch.status))
        }
    }

    return {
        get,
        post,
        postBlob
    }
}

const baseScopes = ["openid", "profile", "mail"];

export const useHttpClientHelper = <T>(requiredScopes: string[], service: (httpClientHelper: HttpClientHelper, 
    config: WidgetConfiguration) => T, includeCredential?: boolean ): T => {
    const sgConnectFetch = useSgConnectFetch([...baseScopes, ...requiredScopes])
    const config = useWidgetConfiguration();

    return useMemo(() => {        
        const httpClient = getHttpClientHelper(sgConnectFetch, config, includeCredential)        
        return service(httpClient, config)
    }, [
        config.environment,
        config.error,
        sgConnectFetch.fetch,
        JSON.stringify(sgConnectFetch.status)
    ]);
}
