import React, { ChangeEvent } from "react";
import { RequestAccessGlobalWorkflowConsumer } from "../../contexts/RequestAccessGlobalWorkflowContext";
import { ResourceProfile, Site } from "../../models/RequestAccessModels";
import { HeaderModal } from "../modal-common/HeaderModal";
import { useUserIsInternal } from "../../services/SgConnectService";
import { requestAccessTranslate } from "../../services/requestAccessTranslate";
import { RequestAccessCheckboxes } from "./RequestAccessCheckboxes";

type ResourceProfileSelected = {
  [id: string]: ResourceProfile;
}

export const BusinessJustificationContent: React.FC = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("");
  const [resourceProfilesSelected, setResourceProfilesSelected] = React.useState<ResourceProfileSelected>({});
  const loggedUserIsInternal = useUserIsInternal();

  const toggleCallback = (resourceProfile: ResourceProfile): void => {
    if (resourceProfilesSelected && resourceProfilesSelected[resourceProfile.id]) {
      const newResourceProfilesSelected: ResourceProfileSelected = {};
      Object.keys(resourceProfilesSelected).forEach(resourceProfileId => {
        if (resourceProfileId !== resourceProfile.id) {
          newResourceProfilesSelected[resourceProfileId] = resourceProfilesSelected[resourceProfileId];
        }
      });
      setResourceProfilesSelected(newResourceProfilesSelected);
    } else {
      setResourceProfilesSelected({ ...resourceProfilesSelected, [resourceProfile.id]: resourceProfile })
    }
  };

  const updateText = React.useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setText(event.target.value);
    },
    [setText]
  );

  const filterProfiles = (site: Site | undefined, hasOnBehalfOf: boolean, onBehalfOfIsInternal: boolean | undefined): ResourceProfile[] => {
    if (!loggedUserIsInternal) {
      return [];
    }

    const allUserProfiles = site?.resourceProfiles ?? [];
    if (hasOnBehalfOf) {
      return allUserProfiles.filter(x =>
        x.exposure === 'ALL'
        || (x.exposure === 'INTERNAL' && onBehalfOfIsInternal)
        || (x.exposure === 'EXTERNAL' && !onBehalfOfIsInternal));
    }

    return allUserProfiles.filter(x => x.exposure === 'ALL' || x.exposure === 'INTERNAL');
  };

  const getDescription = (lang: string, hasResourceProfile: boolean): string => {
    return requestAccessTranslate(lang, hasResourceProfile ? "iamDetails" : "businessJustificationDetails")
  }

  return (
    <div>
      <HeaderModal />
      <RequestAccessGlobalWorkflowConsumer>
        {({ widgetProps, site, hasOnBehalfOf, onBehalfOf, lang, sendRequestAccess }) => {
          const filtredProfiles = filterProfiles(site, hasOnBehalfOf, onBehalfOf.isInternal);
          const hasResourceProfile = filtredProfiles.length > 0;
          return <>
            <div className="modal-body">
              <p className="mb-3">{getDescription(lang, hasResourceProfile)}</p>
              {hasResourceProfile && (
                <RequestAccessCheckboxes
                  options={filtredProfiles.map(v => ({ key: v.id, label: v.name }))}
                  selectedKeys={Object.keys(resourceProfilesSelected)}
                  onChange={(key) => {
                    const profile = filtredProfiles.find(x => x.id === key);
                    profile && toggleCallback(profile);
                  }}
                />
              )}
              <textarea
                style={{ resize: "none" }}
                className="form-control"
                placeholder={requestAccessTranslate(lang, "businessJustificationPlaceholder")}
                rows={6}
                onChange={updateText}
                value={text}
              >
                {text}
              </textarea>
            </div>
            <div className="modal-footer pt-0">
              <button
                type="button"
                className="btn btn-lg mr-3 text-secondary"
                data-dismiss="modal"
              >
                {requestAccessTranslate(lang, "cancel")}
              </button>
              <button
                type="button"
                className={`btn btn-lg ${widgetProps.colorSubmitBtn}`}
                onClick={() => {
                  setLoading(true);
                  sendRequestAccess("/", {
                    serviceKey: site?.serviceKey || '',
                    comment: text,
                    sourceUrl: widgetProps.sourceUrl ?? window.location.hostname,
                    sourceContext: widgetProps.sourceContext ?? "business-justification-button",
                    resourceProfiles: Object.values(resourceProfilesSelected)
                  });
                }}
                disabled={isLoading}
              >
                {requestAccessTranslate(lang, "sendRequest")}
              </button>


            </div>
          </>
        }}
      </RequestAccessGlobalWorkflowConsumer>
    </div>
  );
};
