import React from "react";

export const Loading: React.FC = () => (
  <>
    <div className="hide-on-sgbs-4 blinker blinker-xl m-2" role="status" />
    <div className="hide-on-sgbs-5 spinner-grow p-2" role="status">
      Loading...
    </div>
  </>
);
