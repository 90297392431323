import React from "react";

type PopsAdditionalInfoSelectionProps = {
  values:
  {
    name: string;
    description: string;
  }[]
  | null;
  selected: string[];
  callback: (value: string) => void;
};

export const TextAreaSelection: React.FC<PopsAdditionalInfoSelectionProps> = props => (
  <div>
    <textarea
      style={{ resize: "vertical" }}
      className="form-control input-group-lg"
      placeholder=""
      rows={3}
      onChange={event => {
        const text = event.target.value;
        props.callback(text);
      }}
      value={props.selected[0]}
    >
      {props.selected[0]}
    </textarea>
  </div>
);
