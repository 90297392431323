import React, { FC, ReactNode, createContext, useEffect } from "react";
import { WidgetProps } from "../models/RequestAccessWidgetProps";
import {
  useWidgetConfiguration,
  useFetch,
  useSgConnectFetch,
  FetchFn,
} from "@sg-widgets/react-core";
import { DefaultRequestData, RequestData, Site, defaultSite } from "../models/RequestAccessModels";
import { WidgetConfiguration } from "@sg-widgets/shared-core";
import { getService } from "../services/requests";
import { useRequestUserService } from "../services/requestUserService";
import { RequestAccessGlobalWorkflowReducerStore } from "../models/RequestAccessStore";
import {
  providerInitialState,
  RequestAccessGlobalWorkflowType,
  useGlobalWorkflowReducer,
} from "../reducers/RequestAccessGlobalWorkflowReducer";
import {
  createErrorAction,
  createLoadingAction,
  createResetAction,
  createUpdateSiteAction,
  createUpdateLangAction,
  createOnBehalfOfAction,
} from "../reducers/RequestAccessGlobalWorkflowAction";
import { sendGlobalRequestAccess } from '../services/sendGlobalRequestAccess';
import { transformGlobalRequestAccessResultToEndModalDispatch } from '../services/transformGlobalRequestAccessResultToEndModalDispatch';

type RequestAccessGlobalWorkflowProviderState =
  RequestAccessGlobalWorkflowReducerStore &
  RequestAccessGlobalWorkflowType & {
    widgetProps: WidgetProps;
    hasOnBehalfOf: boolean;
  };

const initialState = {
  ...providerInitialState,
} as RequestAccessGlobalWorkflowProviderState;

export type RequestAccessGlobalWorkflowState =
  Readonly<RequestAccessGlobalWorkflowProviderState>;

const context = createContext<RequestAccessGlobalWorkflowState>(initialState);

const isValidSite = (site: Site): boolean =>
  !!site.name && !!site.serviceKey && (!!site.url || !!site.ressourceId);

const { Provider, Consumer } = context;

const retrieveAndEmitFullSite = (
  sgFetch: FetchFn,
  widgetProps: WidgetProps,
  config: WidgetConfiguration,
  dispatchSite: (site?: Site) => void
): (() => void) => {
  let isMounted = true;
  getService(widgetProps, sgFetch, config).then((retrievedSite) => {
    if (isMounted) {
      dispatchSite(retrievedSite);
      widgetProps.emitGetService(retrievedSite);
    }
  });
  return () => {
    isMounted = false;
  };
};

const retrieveAndEmitSiteRessource = (
  sgFetch: FetchFn,
  widgetProps: WidgetProps,
  config: WidgetConfiguration,
  dispatchSite: (site?: Site) => void
): (() => void) => {
  let isMounted = true;
  getService(widgetProps, sgFetch, config).then((retrievedSite) => {
    if (isMounted && widgetProps.site) {
      const newSite: Site = {
        ...widgetProps.site,
        resourceProfiles: retrievedSite.resourceProfiles,
        ressourceId: retrievedSite.ressourceId,
        activateIAMAccessWidget: retrievedSite.activateIAMAccessWidget,
      };
      dispatchSite(newSite);
      widgetProps.emitGetService(newSite);
    }
  });
  return () => {
    isMounted = false;
  };
};

const RequestAccessGlobalWorkflowProvider: FC<{
  widgetProps: WidgetProps;
  children?: ReactNode;
}> = ({ widgetProps, children }) => {
  const { ressourceId, popsIdentifier, url, site, icid: onBehalfOfIcId } = widgetProps;
  const sgConnectFetch = useSgConnectFetch(["openid", "profile"]);
  const sgFetch = useFetch();
  const config = useWidgetConfiguration();
  const { getRequestUserByIcId } = useRequestUserService();
  const [state, dispatch] = useGlobalWorkflowReducer();
  
  const hasOnBehalfOf = !!onBehalfOfIcId && onBehalfOfIcId.trim().length > 0;

  const dispatchSite = (site?: Site): void => {
    dispatch(createUpdateSiteAction(site));
  };

  useEffect(() => {
    const busValue = "global.language";
    const updateLang = (lang: string | undefined): void => {
      dispatch(createUpdateLangAction(lang ?? "en"));
    };
    config.bus.subscribe(busValue, updateLang);
    return () => {
      config.bus.unsubscribe(updateLang);
    };
  }, [config.bus]);

  useEffect(() => {
    if (site && isValidSite(site)) {
      dispatchSite(site);
      if ((!site.resourceProfiles || site.resourceProfiles.length == 0)
        && !site.popsIdentifier) {
        return retrieveAndEmitSiteRessource(
          sgFetch,
          widgetProps,
          config,
          dispatchSite
        );
      }
      return;
    }
    if (popsIdentifier) {
      dispatchSite(undefined);
      return;
    }
    if (ressourceId || url)
      return retrieveAndEmitFullSite(
        sgFetch,
        widgetProps,
        config,
        dispatchSite
      );
    dispatchSite(defaultSite);
  }, [ressourceId, popsIdentifier, url]);

  useEffect(() => {
    if (hasOnBehalfOf) {
      getRequestUserByIcId(onBehalfOfIcId)
        .then(user => {
          dispatch(createOnBehalfOfAction(user.scope?.isInternal));
        })
        .catch(() => dispatch(createOnBehalfOfAction()))
    } else {
      dispatch(createOnBehalfOfAction());
    }
  }, [onBehalfOfIcId]);

  const sendRequestAccess = (endpoint: string, content: RequestData): void => {
    dispatch(createLoadingAction());
    if (endpoint === "/") {
      (content as DefaultRequestData).url = url;
      if (hasOnBehalfOf) {
        (content as DefaultRequestData).icId = onBehalfOfIcId;
      }
    }
    sendGlobalRequestAccess(endpoint, content, sgConnectFetch, config)
      .then((response) => {
        dispatch(
          transformGlobalRequestAccessResultToEndModalDispatch(
            content,
            response,
            endpoint,
            widgetProps.emitRequestSent
          )
        );
      })
      .catch((error) => {
        dispatch(createErrorAction(error));
      });
  };

  const resetRequestAccess = (): void => dispatch(createResetAction());

  return (
    <Provider
      value={{
        ...state,
        hasOnBehalfOf,
        widgetProps,
        sendRequestAccess: sendRequestAccess,
        resetRequestAccess: resetRequestAccess,
      }}
    >
      {children}
    </Provider>
  );
};

export {
  RequestAccessGlobalWorkflowProvider,
  Consumer as RequestAccessGlobalWorkflowConsumer,
  context as RequestAccessGlobalWorkflowContext,
};
