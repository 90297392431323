import React from "react";

const StepFill = (props: { name: string }): JSX.Element => (
  <li className="sgbs-stepper-step stepper-step fill">
    <div className="sgbs-stepper-step-inner stepper-step-inner">
      <div className="sgbs-stepper-indicator stepper-indicator">
        <i className="icon">check</i>
      </div>
    </div>
    <span className="sgbs-stepper-label stepper-label">{props.name}</span>
  </li>
);

const StepCurrent = (props: { name: string }): JSX.Element => (
  <li className="sgbs-stepper-step stepper-step current blocked">
    <div className="sgbs-stepper-step-inner stepper-step-inner">
      <div className="sgbs-stepper-indicator stepper-indicator">
        <i className="icon">fiber_manual_record</i>
      </div>
    </div>
    <span className="sgbs-stepper-label stepper-label">{props.name}</span>
  </li>
);

const StepInactive = (props: { name: string }): JSX.Element => (
  <li className="sgbs-stepper-step stepper-step inactive">
    <div className="sgbs-stepper-step-inner stepper-step-inner">
      <div className="sgbs-stepper-indicator stepper-indicator" />
    </div>
    <span className="sgbs-stepper-label stepper-label">{props.name}</span>
  </li>
);

const Step = (props: { name: string; value: number; current: number }): JSX.Element => {
  if (props.value === props.current) {
    return <StepCurrent name={props.name} />;
  }
  if (props.value < props.current) {
    return <StepFill name={props.name} />;
  }
  return <StepInactive name={props.name} />;
};

export const PopsStepper = (props: { current: number }): JSX.Element => (
  <ul className="sgbs-stepper stepper text-primary mb-3">
    <li />
    <Step value={1} current={props.current} name="Select Profile" />
    <Step value={2} current={props.current} name="Additional Information" />
    <Step value={3} current={props.current} name="Summary" />
    <li />
  </ul>
);
