import React from "react";
import { RequestAccessGlobalWorkflowConsumer } from "../../contexts/RequestAccessGlobalWorkflowContext";

export const ErrorModal: React.FC<{
  message: string;
}> = ({ message }) => (
  <div className="modal-dialog">
    <div className="modal-content shadow-max border border-danger">
      <div className="modal-header pt-5 pb-0 text-center">
        <div className="d-flex align-items-center flex-column w-100">
          <div
            className="rounded-circle bg-danger-alpha-md bg-danger bg-opacity-10 d-flex align-items-center justify-content-center mb-4"
            style={{ width: "3rem", height: "3rem" }}
          >
            <i className="icon icon-md text-danger line-height-1">warning</i>
          </div>
          <h3 className="modal-title row text-danger mb-2">
            The operation failed
          </h3>
        </div>
      </div>
      <p className="text-large fs-4 text-center text-secondary px-3 mb-4">
        {message}
      </p>
      <div className="modal-body pb-0">
        <p className="text-center" />
      </div>
      <div className="modal-footer d-flex justify-content-center pb-5">
        <RequestAccessGlobalWorkflowConsumer>
          {({ resetRequestAccess }) => (
            <button
              className="btn btn-lg sgbs-btn-default btn-default"
              onClick={resetRequestAccess}
            >
              Retry Request
            </button>
          )}
        </RequestAccessGlobalWorkflowConsumer>
      </div>
    </div>
  </div>
);
