export const inlineStyle = `
/* Override custom SG Bootstrap for idy-request-access before migrate to BS5*/
.modal-dialog .sgbs-stepper {
    padding-left: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.modal-dialog .sgbs-stepper-step {
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
    position: relative
}

.modal-dialog .sgbs-stepper-step.inactive {
    color: rgba(110, 119, 122, .4)
}

.modal-dialog .sgbs-stepper-step.inactive .sgbs-stepper-label {
    color: #6e777a !important
}

.modal-dialog .sgbs-stepper-step.blocked .sgbs-stepper-step-inner::after {
    color: rgba(110, 119, 122, .4)
}

.modal-dialog .sgbs-stepper-step.blocked.muted .sgbs-stepper-step-inner::before {
    color: #000
}

.modal-dialog .sgbs-stepper-step-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.modal-dialog .sgbs-stepper-step-inner::after,
.modal-dialog .sgbs-stepper-step-inner::before {
    content: "";
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 2px;
    background: currentColor
}

.modal-dialog .sgbs-stepper-label {
    font-weight: 500;
    margin-top: .75rem;
    font-size: .875rem;
    display: block
}

.modal-dialog .sgbs-stepper:not(.sgbs-stepper-vertical) .sgbs-stepper-label {
    text-align: center
}

.modal-dialog .sgbs-stepper-indicator {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 2px solid currentColor;
    text-align: center;
    background: 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .icon {
        font-size: 1rem;
    }
}

.modal-dialog .fill .sgbs-stepper-indicator {
    background: currentColor
}

.modal-dialog .sgbs-stepper-no-indicator {
    height: 1.5rem;
    text-align: center;
    background: 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.modal-dialog .sgbs-stepper-step:not(.current) .icon {
    color: #fff;
    height: .8rem;
    line-height: .8rem
}

.modal-dialog button.sgbs-stepper-indicator {
    cursor: pointer;
    padding: 0
}

.modal-dialog .text-primary button.sgbs-stepper-indicator {
    color: #000
}

.modal-dialog .text-secondary button.sgbs-stepper-indicator {
    color: #6e777a
}

.modal-dialog .text-success button.sgbs-stepper-indicator {
    color: #00aa0c
}

.modal-dialog .text-info button.sgbs-stepper-indicator {
    color: #2469ff
}

.modal-dialog .text-warning button.sgbs-stepper-indicator {
    color: #da7400
}

.modal-dialog .text-danger button.sgbs-stepper-indicator {
    color: #dd3724
}

.modal-dialog .text-light button.sgbs-stepper-indicator {
    color: #c4c8ca
}

.modal-dialog .text-dark button.sgbs-stepper-indicator {
    color: #414949
}

.modal-dialog .text-white button.sgbs-stepper-indicator {
    color: #fff
}

.modal-dialog .text-black button.sgbs-stepper-indicator {
    color: #231f20
}

.modal-dialog .text-socgen button.sgbs-stepper-indicator {
    color: #e60028
}

.modal-dialog .text-primary-alt button.sgbs-stepper-indicator {
    color: #fff
}

.modal-dialog .sgbs-stepper .sgbs-stepper-step:first-child {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.modal-dialog .sgbs-stepper .sgbs-stepper-step:first-child .sgbs-stepper-step-inner::before {
    display: none
}

.modal-dialog .sgbs-stepper .sgbs-stepper-step:first-child .sgbs-stepper-label {
    text-align: left
}

.modal-dialog .sgbs-stepper .sgbs-stepper-step:last-child {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.modal-dialog .sgbs-stepper .sgbs-stepper-step:last-child .sgbs-stepper-step-inner::after {
    display: none
}

.modal-dialog .sgbs-stepper .sgbs-stepper-step:last-child .sgbs-stepper-label {
    text-align: right
}

.modal-dialog .sgbs-stepper.sgbs-stepper-vertical .sgbs-stepper-step:last-child .sgbs-stepper-label {
    text-align: left
}

.modal-dialog .sgbs-stepper-vertical {
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.modal-dialog .sgbs-stepper-vertical .sgbs-stepper-step-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%
}

.modal-dialog .sgbs-stepper-vertical .sgbs-stepper-step-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%
}

.modal-dialog .sgbs-stepper-vertical .sgbs-stepper-step-inner::after,
.modal-dialog .sgbs-stepper-vertical .sgbs-stepper-step-inner::before {
    content: "";
    width: 2px;
    height: 100%
}

.modal-dialog .sgbs-stepper-vertical .sgbs-stepper-label {
    position: absolute;
    margin-top: 0;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    left: 3rem;
    white-space: nowrap
}

.modal-dialog .sgbs-stepper-vertical .sgbs-stepper-step:first-child .sgbs-stepper-label {
    top: 0;
    bottom: auto
}

.modal-dialog .sgbs-stepper-vertical .sgbs-stepper-step:last-child .sgbs-stepper-label {
    bottom: 0;
    top: auto
}

// custom checkbox
.modal-dialog .btn.border,
.modal-dialog .border.d-flex.justify-content-between {
    color: #000;
    padding: 0.3125rem 0.9375rem;
    font-size: .875rem;
    text-align: center;
    vertical-align: middle;
    justify-content: start;
}

.modal-dialog .btn.border:hover,
.modal-dialog .border.d-flex.justify-content-between:hover {
    color: #000;
    text-decoration: none;
}

.modal-dialog .btn.border .custom-control-input,
.modal-dialog .border.d-flex.justify-content-between .custom-control-input {
    margin-right: 0.5rem;
}

.modal-dialog .btn.border,
.modal-dialog .border.d-flex.justify-content-between .custom-checkbox {
    display: inherit;
}

.modal-dialog .border.d-flex.justify-content-between .custom-control-input {
    margin-bottom: 0.4rem;
}

.modal-dialog .btn.border:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.modal-dialog .sr-only {
    position: absolute;
    width: 2px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
`