import React, { useContext } from "react";
import { RequestAccessGlobalWorkflowContext } from "../contexts/RequestAccessGlobalWorkflowContext";
import { ButtonModalAccess } from "./button/ButtonModalAccess";
import { Loading } from "../../common/components/Loading";
import { useSgConnectFetch } from "@sg-widgets/react-core";
import { ButtonIAMAccess, canUseIAMWidget } from "./button/ButtonIAMAccess";
import { useUserIsInternal } from "../services/SgConnectService";

export const RequestAccessWidget: React.FC = () => {
  const { site, hasOnBehalfOf, onBehalfOf, widgetProps: { popsIdentifier } } = useContext(RequestAccessGlobalWorkflowContext);
  const { fetch } = useSgConnectFetch(["api.idy-widgets.request-access"]);
  const loggedUserIsInternal = useUserIsInternal();

  if (!onBehalfOf.isFetching) {
    const isInternal = hasOnBehalfOf ? onBehalfOf.isInternal : loggedUserIsInternal;
    if (isInternal && canUseIAMWidget({ site, fetch })) {
      return <ButtonIAMAccess />
    }

    if (site || popsIdentifier) {
      return <ButtonModalAccess />
    }
  }

  return <Loading />
};
