import { Site } from 'sgmarkets-request-access/models/RequestAccessModels';

export const createErrorAction = (message: string) => ({
    type: 'error',
    payload: message,
} as const);

export const createSuccessAction = (message: string) => ({
    type: 'success',
    payload: message,
} as const);

export const createLoadingAction = () => ({
    type: 'loading'
} as const);

export const createResetAction = () => ({
    type: 'reset',
} as const);

export const createUpdateSiteAction = (site?: Site) => ({
    type: 'updateSite',
    payload: site,
} as const);

export const createUpdateLangAction = (lang: string) => ({
    type: 'updateLang',
    payload: lang,
} as const);

export const createOnBehalfOfAction = (isInternal?: boolean) => ({
    type: 'onBehalfOf',
    payload: {
        isInternal,
    },
} as const);

export type Action =
| ReturnType<typeof createErrorAction>
| ReturnType<typeof createSuccessAction>
| ReturnType<typeof createLoadingAction>
| ReturnType<typeof createResetAction>
| ReturnType<typeof createUpdateSiteAction>
| ReturnType<typeof createUpdateLangAction>
| ReturnType<typeof createOnBehalfOfAction>;