import React from "react";
import { HeaderModal } from "../modal-common/HeaderModal";

export const NotFoundContent: React.FC = () => (
  <div>
    <HeaderModal />
    <div className="modal-body">
      <p className="text-xlarge fs-3 font-weight-medium fw-medium mb-3">
        <i className="icon icon-md">error_outline</i> Service not found.
      </p>
      <p>The widget cannot find the application, please retry later</p>
    </div>
    <div className="modal-footer pt-0">
      <button
        type="button"
        className="btn btn-lg mr-3 text-secondary"
        data-dismiss="modal"
      >
        Close
      </button>
    </div>
  </div>
);
