import { SgConnectFetchStatus } from "@sg-widgets/react-core";

export const getSgConnectFetchErrorMessageByStatus = (
  status: SgConnectFetchStatus | null
): string => {
  return status?.reason === "NOT_CONNECTED"
    ? "You are not authenticated. Try to 'Sign In'"
    : status?.reason === "NO_SCOPES_IN_BUS"
    ? "Scopes are not available yet in the bus"
    : status?.reason === "MISSING_SCOPES"
    ? `Required scopes are missing for this widget. The missing scopes are ${status.missingScopes.join(
        ", "
      )}`
    : "Unknown error";
};
