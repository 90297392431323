import { Environment } from './environment'

export const getIcApiUrl = (environment: string): string => {
  switch (environment as Environment) {
    case "production":
      return "https://api-s.sgmarkets.com/services/ic/crm/v2/";
    default:
      return "https://ic-api-hom.fr.world.socgen/2.0/";
  }
};

export const getResearchReferentialsApiUrl = (environment: string): string => {
  switch (environment as Environment) {
    case "production":
      return "https://pretrade-api-referentials.fr.world.socgen/api/v1/";
    default:
      return "https://pretrade-api-referentials-uat.fr.world.socgen/api/v1/";
  }
};

export const getSgConnectUrl = (environment: string): string => {
  switch (environment as Environment) {
    case "production":
      return "https://sso.sgmarkets.com/sgconnect";
    default:
      return "https://sgconnect-hom.fr.world.socgen/sgconnect";
  }
};

export const getApiRequestAccessUrl = (environment: string): string => {
  switch (environment as Environment) {
    case "production":
      return "https://api-z.sgmarkets.com/services/info/request-access/private";
    default:
      return "https://api-z-dev.sgmarkets.com/uat/services/info/request-access/private";
  }
};

export const getIamUrl = (environment: string): string => {
  switch (environment as Environment) {
    case "production":
      return "https://iam.sgmarkets.com";
    default:
      return "https://iam-hom.sgmarkets.com";
  }
};

export const getFormRequestAccessUrl = (
  environment: string,
  returnUrl: string,
  sourceUrl: string,
  sourceContext: string
): string => {
  switch (environment as Environment) {
    case "production":
      return `https://info.sgmarkets.com/en/request-access?returnurl=${returnUrl}&sourceurl=${sourceUrl}&sourcecontext=${sourceContext}`;
    default:
      return `https://info-uat.sgmarkets.com/en/request-access?returnurl=${returnUrl}&sourceurl=${sourceUrl}&sourcecontext=${sourceContext}`;
  }
};

export const getDomainStatisticServiceUrl = (environment: string): string => {
  switch (environment as Environment) {
    case "production":
      return "https://pretrade-api-statistic.fr.world.socgen/api/v2";
    default:
      return "https://pretrade-api-statistic-uat.fr.world.socgen/api/v2";
  }
};

export const getSharedUrl = (environment: string): string => {
  switch (environment as Environment) {
    case "production":
      return "https://shared.sgmarkets.com/";
    default:
      return "https://shared-uat.fr.world.socgen/";
  }
};

export const getInsightUrl = (environment: string): string => {

  switch (environment as Environment) {
    case "production":
      return "https://insight.sgmarkets.com/";
    default:
      return "https://insight-uat.sgmarkets.com/";
  }
};
